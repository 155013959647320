
<div class="img"><img [src]="imgurl" alt=""></div>
<ng-content></ng-content>
<div class="padding" *ngIf="showDetail">
    <h3>"{{eventData?.event?.name}}"</h3>

    <p [innerHTML]="eventData?.event?.description || '...'"></p>

    <hr style="background-color: #dadada; margin: 0;">
    <h3 style=" color: #000;"><b>{{jsonLanguage?.web?.default?.components?.landing_confirm?.when_where || "¿Cuándo y dónde?"}}</b></h3>
    <p>{{jsonLanguage?.web?.default?.components?.landing_confirm?.the_day || "El día "}} <b>{{defaultLanguage}}</b>{{jsonLanguage?.web?.default?.components?.landing_confirm?.on_label || ", en"}} <b>{{eventData?.event?.location?.place}}</b>.  <br><a [href]="landinRoute + tokenLinkId" (click)="gotoMap()">{{jsonLanguage?.web?.default?.components?.landing_confirm?.see_location || "Ver ubicación"}}</a></p>
</div>