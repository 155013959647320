import { Routes, RouterModule} from '@angular/router';
import { RoleGuard } from '../services/role-guard.service';
//Route for content layout without sidebar, navbar and footer for pages like Login, Registration etc...
export const Pages_Routes: Routes = [
    {
        path: '',
         canActivate: [RoleGuard],
         data: { role: 'home'},
        loadChildren: () => import('../../pages/home/home.module').then(m => m.HomeModule)
    },
    {
        path: 'home',
         canActivate: [RoleGuard],
        data: { role: 'home'},
        loadChildren: () => import('../../pages/home/home.module').then(m => m.HomeModule)
    },
    {
        path: 'assistance',
         canActivate: [RoleGuard],
        data: { role: 'assistance'},
        loadChildren: () => import('../../pages/assistance/assistance.module').then(m => m.AssistanceModule)
    },
    {
        path: 'score',
         canActivate: [RoleGuard],
        data: { role: 'score'},
        loadChildren: () => import('../../pages/score/score.module').then(m => m.ScoreModule)
    },
    {
        path: 'messages',
         canActivate: [RoleGuard],
        data: { role: 'messages'},
        loadChildren: () => import('../../pages/chatroom/chatroom.module').then(m => m.ChatroomModule)
    },
    {
        path: 'messages/:chatId',
         canActivate: [RoleGuard],
        data: { role: 'messages'},
        loadChildren: () => import('../../pages/chatroom/chatroom.module').then(m => m.ChatroomModule)
    },
    {
        path: 'community-chat',
         canActivate: [RoleGuard],
        data: { role: 'community'},
        loadChildren: () => import('../../pages/community-chat/community-chat.module').then(m => m.CommunityChatModule)
    },
    {
        path: 'events',
         canActivate: [RoleGuard],
        data: { role: 'home'},
        loadChildren: () => import('../../pages/events/events.module').then(m => m.EventsModule)
    },
    {
        path: 'users',
        canActivate: [RoleGuard],
        data: { role: 'users'},
        loadChildren: () => import('../../pages/user/users.module').then(m => m.UsersModule)
    },

    {
        path: 'roles',
        canActivate: [RoleGuard],
        data: { role: 'roles'},
        loadChildren: () => import('../../pages/roles/roles.module').then(m => m.RolesModule)
    },
    {
        path: 'supports',
        //canActivate: [RoleGuard],
        data: { role: 'support'},
        loadChildren: () => import('../../pages/supports/supports.module').then(m => m.SupportsModule)
    },
    {
        path: 'history',
        //canActivate: [RoleGuard],
        data: { role: 'history'},
        loadChildren: () => import('../../pages/events-history/events-history.module').then(m => m.EventsHistoryModule)
    }
 
]