<div class="modal-header">
    <h6 class="modal-title" id="modal-basic-title"> Enviar Notificación</h6>
    <button type="button" class="close" aria-label="Close" (click)="close()">
       <span aria-hidden="true">&times;</span>
  </button>
  </div>

  <div class="modal-body pb-0">
    <form [formGroup]="NotificationForm">
        <div class="row">
            <div class="col-sm-12">
                <h6>Titulo <span class="text-danger">*</span></h6>
                <div class="input-group">
                    <input type="text" class="form-control" maxlength="100" formControlName="title" placeholder="Aa..."
                    (change)="onChange();" (keyup)="onChange();" (blur)="onBlur()"
                    >
                </div>
                <small class="tx-danger" *ngIf="fieldisValid.fieldIsValid('title', NotificationForm)">Campo requerido. Max 100 caracteres.</small>
                </div>
        </div>

        <ng-container  formGroupName="types">
            <div class="row mt-3 mb-5">
                <div class="col-sm-12">
                    <h6>Usuarios a Notificar <span class="text-danger">*</span></h6>
                        <div class="input-group mt-2">
                            <label class="ckbox cursor-pointer"><input formControlName="HOST" type="checkbox"><span>HOST</span></label>
                            <label class="ckbox cursor-pointer"><input formControlName="STAFF"type="checkbox" ><span>Recepción</span></label>
                            <label class="ckbox cursor-pointer"><input formControlName="GUEST" type="checkbox"><span>Invitados</span></label>
                        </div>
                    </div>
            </div>
        </ng-container>

        <div class="row mt-2">
            <div class="col-sm-12">
                <h6>Descripción <span class="text-danger">*</span></h6>
                <div class="input-group">
                    <textarea type="text" class="form-control" rows="4" placeholder="Aa..." maxlength="250" formControlName="description" style="height: 109px;"
                    (change)="onChange();" (keyup)="onChange();" (blur)="onBlur()"
                    ></textarea></div>
                <small class="tx-danger" *ngIf="fieldisValid.fieldIsValid('description', NotificationForm)">Campo requerido. Max 250 caracteres.</small>
                </div>
        </div>
    </form>
  </div>

    <div class="modal-footer justify-content-between mt-3">
        <button type="button" class="btn btn-danger" (click)="close()">Cancelar</button>
        <button class="btn btn-success" [disabled]="!isValid"  (click)="submit()" type="button">Enviar <i class="mdi mdi-send ml-2"></i></button>
    </div>

    <swal #swalDanger class="d-none" title="Estas seguro de eliminar esta actividad de la agenda?" text="" icon="info" [showCancelButton]="true" [focusCancel]="true" [swalOptions]="{confirmButtonText: 'Aceptar', cancelButtonText: 'Cancelar'}"></swal>
