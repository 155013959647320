import * as moment from "moment";

export const datePicker = {
  locale: {
    spanish: {
      displayFormat: 'DD/MM/YYYY',
      "customRangeLabel": "Personalizadas",
      "applyLabel": "Aplicar",
      "cancelLabel": "Cancelar",
      "daysOfWeek": [
        "Do",
        "Lu",
        "Ma",
        "Mi",
        "Ju",
        "Vi",
        "Sa"
      ],
      "monthNames": [
        "Enero",
        "Febrero",
        "Marzo",
        "Abril",
        "Mayo",
        "Junio",
        "Julio",
        "Agosto",
        "Septiembre",
        "Octubre",
        "Noviembre",
        "Diciembre"
      ],
      "firstDay": 1
    },
    english: {
      displayFormat: 'DD/MM/YYYY',
      "customRangeLabel": "Customized",
      "applyLabel": "Apply",
      "cancelLabel": "Cancel",
      "daysOfWeek": [
        "Sun",
        "Mon",
        "Tue",
        "Wed",
        "Thu",
        "Fri",
        "Sat"
      ],
      "monthNames": [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December"
      ],
      "firstDay": 1
    }
  },
  customRange: {
    spanish: {
      'Semana actual': [moment().startOf("isoWeek"), moment().endOf("isoWeek")],
      'Mes Actual': [moment().startOf("month"), moment().endOf("month")],
      'Mes pasado': [moment().subtract(1, "month").startOf("month"), moment().subtract(1, "month").endOf("month")],
      
    },
    english: {
      'Current week': [moment().startOf("isoWeek"), moment().endOf("isoWeek")],
      'Current month': [moment().startOf("month"), moment().endOf("month")],
      'Last month': [moment().subtract(1, "month").startOf("month"), moment().subtract(1, "month").endOf("month")],
      
    }
  },
  localeDateTimePicker: {
    spanish: {
      displayFormat: 'DD/MM/YYYY HH:mm',
      "customRangeLabel": "Personalizadas",
      "applyLabel": "Aplicar",
      "cancelLabel": "Cancelar",
      "daysOfWeek": [
        "Do",
        "Lu",
        "Ma",
        "Mi",
        "Ju",
        "Vi",
        "Sa"
      ],
      "monthNames": [
        "Enero",
        "Febrero",
        "Marzo",
        "Abril",
        "Mayo",
        "Junio",
        "Julio",
        "Agosto",
        "Septiembre",
        "Octubre",
        "Noviembre",
        "Diciembre"
      ],
      "firstDay": 1
    },
    english: {
      displayFormat: 'DD/MM/YYYY',
      "customRangeLabel": "Customized",
      "applyLabel": "Apply",
      "cancelLabel": "Cancel",
      "daysOfWeek": [
        "Sun",
        "Mon",
        "Tue",
        "Wed",
        "Thu",
        "Fri",
        "Sat"
      ],
      "monthNames": [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December"
      ],
      "firstDay": 1
    }
  },

}

//'Todos': [moment('2021-01-01'), moment().endOf("week")],