import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';

@Pipe({
  name: 'photoGuest'
})
export class PhotoGuestPipe implements PipeTransform {

  constructor(private domSanitizer: DomSanitizer){}

  transform(img: string): any {
    return img?this.domSanitizer.bypassSecurityTrustResourceUrl(`${environment.apiUrl}${img}`):'../../assets/img/users/avatar.png';
  }

}
