import { Component, OnInit, AfterViewInit, Inject, OnChanges, EventEmitter, Output } from '@angular/core';
import PerfectScrollbar from 'perfect-scrollbar';
import { Subscription } from 'rxjs';
import { LayoutService } from '../../services/layout.service';
import { NavService } from '../../services/nav.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';


import { DOCUMENT } from '@angular/common';
import { Router } from '@angular/router';


import { UserService } from '../../services/user.service';
import { EventService } from '../../services/event.service';
import { SocketService } from '../../services/socket.service';
import { NotificationsService } from '../../services/notifications.service';
import { HttpService } from '../../services/http.service';
import { LanguageService } from '../../services/http/language.service';
import { UserService as httpUserService } from '../../services/http/user.service';
import { AlertService } from '../../services/alert.service';
import * as moment from 'moment';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, AfterViewInit {

  public user: any = {
    name: 'Juan Perez',
    userName: "JuanP",
    email: 'jp@mail.com',
    shortName: 'JP',
    image: ''
  }
  public isCollapsed = true;
  public isSidebar = false;
  public config: any = {};
  public notifications = [];
  public LanguageList = [];
  public LanguageSelected: any = {};
  public eventSelected;
  layoutSubscription: Subscription;
  toggleClass = "fe fe-maximize";


  constructor(
    public layoutService: LayoutService,
    public navServices: NavService,
    public userService: UserService,
    public modalService: NgbModal,
    private router: Router,
    private eventService: EventService,
    public notificationService: NotificationsService,
    public languageService: LanguageService,
    public _userServices: httpUserService,
    public http: HttpService,
    public toastService: AlertService
  ) {
    this.layoutSubscription = layoutService.changeEmitted.subscribe(
      direction => {
        const dir = direction.direction;
      }   
    )

    this.notificationService.listNotifications();
 
  }

  async ngOnInit() {
    this.toggleSidebar()

    this.eventSelected = sessionStorage.getItem("eventSelected");
    this.LanguageList = this.userService?.login?.company?.languages;
    this.LanguageSelected = this.LanguageList.find(item => {return item?.name == this.userService?.user?.language});   
    
    moment.locale(this.LanguageSelected.name == "ESP" ? 'ES' : this.LanguageSelected.name == "ENG" ? 'EN' : 'ES'); 

  }

  categories = [
  ]

  async openNotifications(){
    await this.notificationService.listNotifications();
    await this.notificationService.updateNotifications();
  }

  toggleSidebarNotification() {
    this.layoutService.emitSidebarNotifyChange(true);
  }

  toggleSidebar() {
    if ((this.navServices.collapseSidebar = !this.navServices.collapseSidebar)) {
      document.querySelector('.main-body').classList.add('main-sidebar-hide');
    }
    else {
      document.querySelector('.main-body').classList.remove('main-sidebar-hide');
    }
  }

  toggleMobileSidebar() {
    if ((this.navServices.collapseSidebar = !this.navServices.collapseSidebar)) {
      document.querySelector('.main-body').classList.add('main-sidebar-show');
    }
    else {
      document.querySelector('.main-body').classList.remove('main-sidebar-show');
    }
  }

  ngAfterViewInit() {
    const sidebar = document.querySelector('.side-menu');
    let ps = new PerfectScrollbar(sidebar);
    this.getUserInfo();
  }

  async signOutHandler() {
    this.userService.signOutCurrentUser();
    await this.eventService.clearEventSelected()
    this.router.navigate(['/login']);
  }

  async getUserInfo() {
 
    this.user.shortName = this.userService.user.shortName;
    this.user.name = this.userService.user.name;

    this.user.email = this.userService.user.email;
    this.user.image = this.userService.user.image;

  }


  openModal(template: any, size = 'xl') {
    
    this.modalService.open(template, { size: size, backdrop: 'static' })
  }

  close() {
    this.modalService.dismissAll();
  }

  public changeLanguage(language){
    try {
    
      this.LanguageSelected = language;

      this.languageService.getLanguage(language?.file)
      .then((response) => {
        
        let obj = {
          language: language?.name,
          jsonLanguage: JSON.stringify(response)
        }
          this._userServices.setLanguageUser(obj)
          .then(async () => {
            this.userService.user.jsonLanguage = response
            window.location.reload();
          })
          .catch((e) => {
            console.log(e);
          })

      })
      .catch((e) => {
        console.log(e);
      });  

    } catch (error) {
     console.log(error);
    }
  }

}

