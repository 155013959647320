import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from "@angular/router";
import { Observable } from "rxjs";
import { SocketService } from "./socket.service";
import { UserService } from "./user.service";

@Injectable({
    providedIn: 'root'
})

/**
 * Guard para verificar que el usuario tenga permiso de acceso y/o rol. El nombre del rol se encuentra en UserService
 * 
 * INSTRUCCIONES DE USO: 
 * 1) Utilizar en canActivate de cada modulo
 * 2) Proporcionar un objeto data: {role: ROL_ESPERADO}
 */

export class RoleGuard implements CanActivate {
    private expectedRole: string

    constructor(
        private userService: UserService, 
        private router: Router,
        private socket: SocketService,
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
        const domain = window.location.hostname;
        let subdomain:string = '';

        this.socket.disconnect();
        this.socket.clearRooms();
        this.socket.connect();
        
         if (this.userService.user.roleName === "ADMIN") {
             return true
         }

         this.expectedRole = route.data.role
        
         if (this.userService.user.permissions[this.expectedRole]?.access  || this.userService.user.permissions[this.expectedRole]?.active) {
             return true
         } else {
             let firstPermission:any = Object.entries(this.userService.user.permissions);
             firstPermission = firstPermission.filter(function ([key, value]) {
                 return value["active"];
             });
            firstPermission = firstPermission[0][0];
             this.router.navigate([`/${firstPermission}`])
        //this.router.navigate([`/home`])

         }
    }

}