import { NotificationsService } from './notifications.service';

//import { AuthConstants } from '../../config/AuthConstants';
// import { StorageService } from './storage.service';
import { Injectable } from '@angular/core';
// import { Socket, SocketIoModule } from 'ngx-socket-io';
import { environment } from '../../../environments/environment';
import * as io from 'socket.io-client';
import { UserService } from './user.service';
import { Observable } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class SocketService{

  private socket: io.Socket;
  private rooms: string[] = [];

  constructor(
    private userServices: UserService,
    private notificationsService: NotificationsService
  ) { 
    if(this.userServices.login) this.connect();
  }

  public events(){
    this.socket.on('update', data => {/*console.log(data)*/})
    this.socket.on('connect_error', err => console.log(err))
    this.socket.on('connect_failed', err => console.log(err))
    this.socket.on('disconnect', (reason) => {
      if (
        reason == "io server disconnect" ||
        reason == "ping timeout" || 
        reason == "transport close" ||
        reason == "transport error"
        ) 
        {
          //this.connect();
      }
    })
    this.socket.on('connect',() =>{
      //console.log("Successfully connected! ",environment.socketUrl);
      
      this.connectRooms();
      this.listenerNotifications()
    })
  }

  public listenData(chennel:string): Observable<any> {
    let observable = new Observable(observer => {
      this.socket.on(chennel,(data)=>{
        observer.next(data);
       });
    })
    return observable;
  }

  public removeListener(event: string){
    this.socket.removeAllListeners(event);
  }
  
  public disconnect(): any {
    return this.socket?this.socket.disconnect():null;
  }

  async connect(token: string = null) {   
    this.socket?this.disconnect():null;
    this.socket = null;
    !this.socket?this.socket = io.connect(environment.socketUrl,{
      auth: {
        token: token?token:this.userServices.login.token
      }
    }):this.socket;
    this.events();
    
  }

  async connectRooms(){
    this.rooms.forEach(room => {
      this.socket.emit('joinRoom', {room: room});
    });
  }

  async addRoom(r){
    this.rooms.push(r);
  }

  async removeRoom(r){
    this.rooms.splice(this.rooms.indexOf(r), 1);
    
  }

  async clearRooms(){
    this.rooms = [];
    this.addRoom(this.userServices.user.socketRoom + this.userServices.user._id); 
  }

  async listenerNotifications(){
    this.removeListener('newNotification');
    this.listenData(`newNotification`).subscribe(async (data) => {
      this.notificationsService.update(data);
      this.notificationsService.unread = this.notificationsService._Notifications.filter(notification =>{
        return !notification.view;
      }).length;
    });
  }

}
