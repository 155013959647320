<div class="label p-0 mb-2" *ngIf="separators">{{jsonLanguage?.web?.default?.components?.community_chat?.notice_label ||"Aviso"}}</div>
<div class="card overflow-hidden border-0">
    <!-- <i class="fa fa-{{item.icon | lowercase}} watermark"></i> -->
    <img class="watermark" src="../../../assets/img/alerts/icons/{{item.icon | lowercase}}.png" alt="">
    <div class="pic"><img src="../../../assets/img/alerts/icons/{{item.icon | lowercase}}.png" alt=""></div>
    <div class="text">
    <b>{{item.text || "..."}}</b>
    <span [innerHTML]="item.subtext"></span>
    </div>
</div>
<div class="label">{{item.date | moment:'ll - hh:mm A'}}</div>
