import { Injectable } from '@angular/core';
import { HttpService } from '../http.service';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {

  constructor(private http: HttpService) { }

  public getLanguage = (fileName: string): Promise<any> => this.http.getEva(`api/company/lang/${fileName}`).toPromise()


}
