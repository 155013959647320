import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DecryptChatPipe } from 'src/app/shared/pipes/decrypt-chat.pipe';
import { DecryptGuestInfoPipe } from 'src/app/shared/pipes/decrypt-guest-info.pipe';
import { DecryptPipe } from 'src/app/shared/pipes/decrypt.pipe';
import { UserService } from 'src/app/shared/services/user.service';
import { EventService } from 'src/app/shared/services/event.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { IChatMsg } from 'src/app/interfaces/ichatmsg';

@Component({
  selector: 'app-chat-container',
  templateUrl: './chat-container.component.html',
  styleUrls: ['./chat-container.component.scss'],
})
export class ChatContainerComponent {

  @Input() chatSelected: any = null;
  @Input() messages: any = null;
  @Input() btnMoreMsg: boolean = false;
  @Input() private: boolean;
  @Input() guestId: string;

  @Output() moreMsg: EventEmitter<string> = new EventEmitter<string>();

  public today = new Date();

  public userData: {};
  public jsonLanguage: any = {};
  chat: IChatMsg[] = []

  @Input() defaultBanner: IChatMsg;

  constructor(
    private userService: UserService,
    public decrypt: DecryptPipe,
    public decryptChatPipe: DecryptChatPipe,
    public decryptGuestInfoPipe: DecryptGuestInfoPipe,
    public eventService: EventService,
    private modalService: NgbModal,
   
  ) { }

  ngOnChanges() {
    this.chat = this.messages;
    this.jsonLanguage = this.userService?.user?.jsonLanguage;

  }

  isMyMessage(id: string) {
    if (this.userService.user._id == id) return true;
    return false;
  }

  public sendMessage(msg) {
    this.chat.push({
      type: "MESSAGE",
      user: { name: null, img: null },
      date: new Date(),
      text: msg,
    })
  }

  moreMessages() {
    this.moreMsg.emit("1")
  }

 public showUserInfo(e : any) {
   var type: string;
   if (!(e.user?.email) || (this.userService.user._id === e.user?._id && this.userService.user?.userName?.toLowerCase() === 'admin')) {
      type = "ADMIN"
    }
   else if ((this.eventService._event?.userCreate === e.user._id)) {
      type = "CREADOR"
    }
   else if (this.eventService._event?.hostUsers.includes(e.user._id)) {
      type = "HOST"
    }
   else if (this.eventService._event?.staff.includes(e.user._id)) {
      type = "RECEPCIÓN"
    }
    else{
      type = "INVITADO"
    }

    this.userData = {
      ...e.user,
      type,
      date : e.date
    };


  }

  openModal(template: any, size = 'xxl') {
    this.modalService.open(template, { size: size })
  }
}
