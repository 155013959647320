import { Component, EventEmitter, OnInit, Output, Input } from '@angular/core';
@Component({
  selector: 'card-event',
  templateUrl: './card-event.component.html',
  styleUrls: ['./card-event.component.scss']
})
export class CardEventComponent implements OnInit {
  
  @Input() event: any = null; 
  @Input() mode: any = null; 
  @Input() selected: boolean = false; 

  constructor() { }

  ngOnInit(): void {
  }
}
