import { Pipe, PipeTransform } from '@angular/core';
import { UserService } from '../services/user.service'
@Pipe({
  name: 'status'
})
export class StatusPipe implements PipeTransform {

  private status = {
    CREATED:  this.userService?.user?.jsonLanguage?.web?.default?.pipes?.status?.created || "Próximamente",
    ACTIVE:   this.userService?.user?.jsonLanguage?.web?.default?.pipes?.status?.active || "En vivo",
    FINISHED: this.userService?.user?.jsonLanguage?.web?.default?.pipes?.status?.finished || "Finalizado",
    POSTPONED:this.userService?.user?.jsonLanguage?.web?.default?.pipes?.status?.postponed ||"Pospuesto",
    CANCELED: this.userService?.user?.jsonLanguage?.web?.default?.pipes?.status?.canceled || "Cancelado",
    DELETED:  this.userService?.user?.jsonLanguage?.web?.default?.pipes?.status?.deleted || "Eliminado",
  }
  constructor(
    public userService: UserService
    ){}

  transform(value: string, ...args: unknown[]): unknown {
    return this.status[value];
  }

}
