import { Component, EventEmitter, Input,OnInit , Output } from '@angular/core';
import { UserService } from 'src/app/shared/services/user.service';

@Component({
  selector: 'app-chat-input',
  templateUrl: './chat-input.component.html',
  styleUrls: ['./chat-input.component.scss'],
})
export class ChatInputComponent implements OnInit {

  @Output() onSend: EventEmitter<string> = new EventEmitter<string>();
  @Input() status : string = "";

  public jsonLanguage: any = {};

  constructor(
    private userService: UserService,

  ) {
  }

  ngOnInit(){
    this.jsonLanguage = this.userService?.user?.jsonLanguage;
  }

  send(msg){
    if(msg && msg.trim() !== '') { this.onSend.emit(msg);}}
}
