<form [formGroup]="locationForm">
    <div class="modal-body pb-0 pt-2 border-top">
        <div class="row">
          <div class="col-md-7">
            <div class="row">

              <div class="col-sm-12 mb-2">
                <h6>{{jsonLanguage?.web?.default?.components?.events?.new_event_modal?.location_step?.name_place_label  || "Nombre del lugar"}} <span class="text-danger">*</span></h6>
                <div class="input-group">
                  <input type="text"  class="form-control" formControlName="place"  [placeholder]="jsonLanguage?.web?.default?.components?.events?.new_event_modal?.location_step?.name_place_placeholder || 'Nombre del lugar'" maxlength="80" 
                   />
                </div>
                <small class="tx-danger" *ngIf="fieldisValid.fieldIsValid('place', locationForm)">{{jsonLanguage?.web?.default?.components?.events?.new_event_modal?.location_step?.name_place_validation || "Campo requerido.  Max 80 caracteres"}}</small>

              </div>
              <div class="col-sm-12 mb-3">
                <h6>{{ jsonLanguage?.web?.default?.components?.events?.new_event_modal?.location_step?.direction_label || "Dirección"}} <span class="text-danger">*</span></h6>
                <div class="input-group">
                  <div class="input-group-prepend">
                    <div class="input-group-text">
                      <i class="fe fe-search lh--9 op-6"></i>
                    </div>
                  </div>
                  <input type="text" #searchGoogle class="form-control" value="{{address}}" formControlName="address" [placeholder]="jsonLanguage?.web?.default?.components?.events?.new_event_modal?.location_step?.direction_placeholder || 'Dirección'" maxlength="500" 
                  />
                </div>
                  <small class="tx-danger" *ngIf="fieldisValid.fieldIsValid('address', locationForm)">{{jsonLanguage?.web?.default?.components?.events?.new_event_modal?.location_step?.direction_validation || "Campo requerido.  Seleccione la direccion"}}</small>
              </div>
            </div>
          </div>
          <div class="col-md-5">
            <h6>{{jsonLanguage?.web?.default?.components?.events?.new_event_modal?.location_step?.reference_label || "Referencia"}}</h6>
            <textarea type="text" class="form-control mb-3" rows="4" [placeholder]="jsonLanguage?.web?.default?.components?.events?.new_event_modal?.location_step?.reference_placeholder  || 'Referencia (Opcional)'" maxlength="250" formControlName="references" style="height: 109px;"
            ></textarea>
          </div>
      </div>
      <div class="row">
          <div class="col-sm-12 text-center pb-3">
              <b class="text-dark" *ngIf="manageEvents" >{{jsonLanguage?.web?.default?.components?.events?.new_event_modal?.location_step?.map_use_label  || "Para seleccionar la ubicación, arrastre el marcador a la posición deseada"}}</b>
          </div>
      </div>
      <div class="row">
        <div class="col-sm-12 border-top p-0 map-height">
          <agm-map #googlemap 
            [latitude]="coordinates.lat" 
            [longitude]="coordinates.lng" 
            [zoom]="coordinates.zoom"  
            [streetViewControl]="false" 
            [maxZoom]="18" 
            [mapTypeControl]="true" 
            [styles]="mapStyle"
            [zoomControl]="true" 
            [mapDraggable]="true" 
            >
            <agm-marker [latitude]="coordinates.lat" [longitude]="coordinates.lng" [zIndex]="1000" 
            animation="DROP" (dragEnd)="markerDragEnd($event)"
            [markerDraggable]="manageEvents" 
              >
              <agm-info-window>
                <strong>{{jsonLanguage?.web?.default?.components?.events?.new_event_modal?.location_step?.mark_info_label || "Info del marcador"}}</strong>
                <br>
                <span>{{jsonLanguage?.web?.default?.components?.events?.new_event_modal?.location_step?.mark_coord_label}}: {{coordinates.lat}} , {{coordinates.lng}}</span>
              </agm-info-window>
            </agm-marker>
          </agm-map>
        </div>
      </div>
    </div>
  </form>

  <div class="modal-footer justify-content-between">
    <button type="button" (click)="close()" class="btn btn-danger"> {{ (manageEvents) ?  jsonLanguage?.web?.default?.components?.events?.new_event_modal?.location_step?.btn_cancel_label || "Cancelar" : jsonLanguage?.web?.default?.components?.events?.new_event_modal?.location_step?.btn_close_label  || "Cerrar"}}</button>
    <div>
      <button class="btn btn-dark mr-3" type="button" awPreviousStep><i class="fa fa-chevron-left mr-2"></i> {{jsonLanguage?.web?.default?.components?.events?.new_event_modal?.location_step?.btn_back_label || "Atrás"}}</button>
      <button class="btn btn-dark" type="button" (click)="NextStep()" [disabled]="!locationForm.valid">{{jsonLanguage?.web?.default?.components?.events?.new_event_modal?.location_step?.btn_accept_label || "Siguiente"}}<i class="fa fa-chevron-right ml-2"></i></button>
    </div>
  </div>