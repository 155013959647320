
export const environment = {
  production: false,
  urlAdmin: 'https://admin.fdkapp.com/', 
  //urlAdmin: 'http://localhost:9000/', 

  url_monday: 'https://api.monday.com/v2',
  token_monday: 'eyJhbGciOiJIUzI1NiJ9.eyJ0aWQiOjQ4NjQwMTY3LCJ1aWQiOjE0NDk4ODIxLCJpYWQiOiIyMDIwLTA2LTA5VDAxOjU4OjE1LjAwMFoiLCJwZXIiOiJtZTp3cml0ZSJ9.S3uFnk5FIim4KKacAv2V5BbFvakaEj51lZQBJd5w_ZA',
  columna_app_monday: 'Eventos',
  apiUrl: '',
  clientUrl: '',
  socketUrl: '',
  subdomain: '',
  //domainadmin: 'local' 
  domainadmin:   'produccion'
};