import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpService } from './http.service';

@Injectable({
  providedIn: 'root'
})
export class GetEventByTokenService {

  public get = (url: string, contentType?: string): Observable<any> => this.http.get(`${environment.urlAdmin}${url}`, contentType ? { headers: { "Content-Type": contentType } } : {});

  public eventData = [];
  public eventNotFound: boolean = false;
  public error;
  constructor(
    private httpService: HttpService,
    private http: HttpClient,
    private router: Router

  ) { }

  public async getEventByToken(token:string):Promise<any>{
    return new Promise(async(resolve) => {
    try {
       let response = await  this.get(`api/login/company/${environment.domainadmin}`).toPromise();
        var data = response.data;
        environment.apiUrl = data.server;
        environment.socketUrl = data.server;
    } catch (error) {
      console.log(error);
    }
    try {
      this.eventNotFound = false;
      this.eventData = await this.httpService.get(`api/events/getByTokenLink/`+ token).toPromise();
    } catch (error) {

      if (error.status == 406) {
        this.eventNotFound = true;
        this.error = error?.error?.data;
      }
    }
    resolve(this.eventData);
  });
  }
}
