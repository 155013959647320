<div class="main-body leftmenu main-sidebar-hide" [ngClass]="mainSidebarOpen">
    <div class="page" style="--primary: {{colors.color?.primary}};--secondary: {{colors.color?.secondary}};--tertiary: {{colors.color?.tertiary}};">

        <!-- Sidemenu -->
        <app-sidemenu (mouseover)="hoverEffect($event)" (mouseout)="hoverEffect($event)" [isOpen]="mainSidebarOpen.length"></app-sidemenu>
        <!-- /Sidemenu -->

        <!-- Header -->
        <app-header></app-header>
        <!-- /Header -->

        <!-- Main Content -->
        <div class="main-content side-content pt-0" (click)="clickonBody()">
            <div class="container-fluid">
                <div class="inner-body">
                    <router-outlet (activate)="hoverEffect($event,false)"></router-outlet>
                </div>
            </div>
        </div>
        <!-- /Main Content -->


        <!-- Footer -->
        <app-footer></app-footer>
        <!-- /Footer -->
        <!-- Notification-sidebar -->
        <app-notification-sidebar></app-notification-sidebar>
        <!-- /Notification-sidebar -->

    </div>

</div>