import { Router } from '@angular/router';
import { HttpService } from 'src/app/shared/services/http.service';
import { Injectable } from '@angular/core';
import { AppColorService } from './app-color.service';

@Injectable({
  providedIn: 'root'
})
export class EventService {


  public _event: any = { _id: '' }; // Cambiar segun evento en DB

  constructor(
    private http: HttpService,
    private color: AppColorService,
    private router: Router
  ) {
  }

  public async setEventSelected(eventId: string) {
    try {
      await this.http.get(`api/events/${eventId}`).toPromise().then((response) => {
        sessionStorage.setItem('eventSelected', eventId);
        this._event = response.data
        this.color.color = this._event.colors
      })
    } catch (error) {
      console.log(error)
      this.clearEventSelected()
    }
  }

  public async clearEventSelected() {
    sessionStorage.removeItem('eventSelected')
    sessionStorage.clear();
    this._event = { _id: '' };
    this.color.color = this.color.defaultColors
    this.router.navigate(['/events']);
  }
}
