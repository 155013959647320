import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PipesModule } from 'src/app/shared/pipes/pipes.module';
import { EventCardComponent } from './event-card/event-card.component';
import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';

@NgModule({
  declarations: [
    EventCardComponent,
    FooterComponent,
    HeaderComponent,
    
  ],
  imports: [
    PipesModule,
    CommonModule
  ],
  providers:[
    
  ],
  exports:[
    EventCardComponent,
    FooterComponent,
    HeaderComponent,
  ]
})
export class PortalComponentsModule { }
