<div class="wrap cursor-pointer">
    <div class="icon bg-white">
        <ng-container *ngIf="(eventService._event?._id !== '' && eventService._event?._id !== null && eventService._event?.image); else iconCal">
            <img [src]="eventService._event?.image | domSanitizer"/>
        </ng-container>

        <ng-template #iconCal>
            <i class="fe fe-calendar"></i>
        </ng-template> 
    </div>

    <div class="info text-overflow border-left">
        <small>{{jsonLanguage?.web?.default?.sidemenu?.event_selected || "Evento Seleccionado"}}</small>
        <div class="text-overflow" *ngIf="(eventService._event?._id !== '' && eventService._event?._id !== null); else eventNull">
            <b> {{eventService._event?.name}}</b>
        </div>

        <ng-template #eventNull>
            <b class="text-muted">{{jsonLanguage?.web?.default?.sidemenu?.even_not_selected  || "Sin Evento"}}</b>
        </ng-template> 
    </div>
</div>
 

<ng-template #modalSelectEvent let-modal>
    <app-modal-event-select (onClose)="modal.dismiss()"></app-modal-event-select>
</ng-template>