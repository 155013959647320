import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ILogin } from '../../interfaces/ilogin'

import { environment } from '../../../environments/environment';
import { CryptoSharkService } from './crypto-shark.service';
import { HttpService } from './http.service';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { LanguageService } from './http/language.service';
//import { AuthConstants } from '../../config/AuthConstants';
import { UserService as httpUserService } from '../../shared/services/http/user.service';
@Injectable({
  providedIn: 'root',
})
/**
 * Servicio administrador de sesión de usuario
 */
export class UserService {
  private _login: ILogin;
  private _user: any = {}
  public mensaje: string = '';

  constructor(
    private httpService: HttpService,
    private router: Router,
    private cryptoService: CryptoSharkService,
    private http: HttpClient,
    private languageService: LanguageService,
    public _userServices: httpUserService,

  ) { }

  /**
   * Guardar los datos del usuario
   * @param login Credenciales del usuario que inició sesión
   */
  signInCurrentUser(login: ILogin) {
    // AuthConstants.login = login;
    localStorage.setItem('ldt', JSON.stringify(login));
  }

  /**
   * Obtener datos de la sesión del usuario
   */
  get login() {
    return this._login
  }

  /**
   * Obtener datos del usuario
   */
  get user() {
    return this._user
  }

  setCashRegister(id) {
    this._user.cashRegister = id;
  }
  setCashRegisterTitle(title) {
    this._user.cashRegisterTitle = title
  }

  public get = (url: string, contentType?: string): Observable<any> => this.http.get(`${environment.urlAdmin}${url}`, contentType ? { headers: { "Content-Type": contentType } } : {});

  /**
   * Obtener datos de sesión del usuario validUser()
   * @param returnUrl Url a la que regresará una vez que haya iniciado sesión
   */


  getCurrentUser(returnUrl: string): Promise<boolean> {
    return new Promise((resolve) => {
      this._login = JSON.parse(localStorage.getItem(`ldt`));

      //this._login = AuthConstants.login;
      if (!this._login) {
        this.router.navigate(['/login'], { queryParams: { returnUrl } });
        resolve(false);
      }

      environment.apiUrl = this._login.company.server;
      environment.socketUrl = this._login.company.server;
      this.httpService.get(`api/login`).subscribe(async (response) => {  

        this._login.company.key = response.key; //<-- ENCRYPT REQUESTS  
        this._login.company.dk = this.cryptoService.decrypt(response.datakey,response.key);//<-- DECRYPT RESPONSES 
        this._login.company.dkg = this.cryptoService.decrypt(response.datakeyG,response.key);//<-- DECRYPT GUEST INFO 
        this._login.company.logo = response.companyLogo;
        this._login.company.darkLogo = response.darkLogo;
        
        this._user.email = response.email != undefined ? this.cryptoService.decrypt(response.email, this._login.company.dk) : "";
        this._user.name = this.cryptoService.decrypt(response.name, this._login.company.dk)        
        this._user.userName = this.cryptoService.decrypt(response.nickname, this._login.company.dk)
        this._user._id = this.cryptoService.decrypt(response.id, this._login.company.dk)
        this._user.image = response.image;
        this._user.companyName = response.companyName;
        this._user.roleName = response.roleName;
        this._user.permissions = { ...response.permissions };  
        this._user.socketRoom = response.socketRoom;   
        this._user.jsonLanguage = response.jsonLanguage;
        this._user.language = response.language;

        var shortName = this._user.name.split(' ');
        if (shortName.length === 1) { shortName = shortName[0].substring(0, 2) }
        else { shortName = shortName[0].substring(0, 1) + shortName[1].substring(0, 1); }
        this._user.shortName = shortName;

    
        if(!response?.jsonLanguage?.file_version && response.language){// Solucion temporal a la perdida de archivo de traduccion al reiniciar servicio de back y recargar web
          let file = "";
          if(response.language == "ENG"){
            file = "eng.json"
          }else if(response.language == "ESP"){
            file = "es.json"
          }   

          this._user.jsonLanguage = await this.getLanguage(file);          
        }

        //remplaza el texto del cliente
        if(this._user?.jsonLanguage?.web?.default && this._user?.jsonLanguage?.web[this._user.companyName]) {
          this._user.jsonLanguage.web.default = await this.replaceLenguage(this._user?.jsonLanguage?.web[this._user.companyName], this._user?.jsonLanguage?.web?.default);
        }

        resolve(true)
      })


    })
  }
  

  /**
   * Cerrar sesión
   * Borrar los datos de sesión del usuario
   */
  signOutCurrentUser() {
    //environment.apiUrl = 'http://10.10.2.34:9000/'
    localStorage.removeItem('ldt')
    sessionStorage.clear();
    
    // AuthConstants.login = null;
    this.router.navigate(['/login'])
  }


  public async replaceLenguage(objectCustomer: Object, objectDefault: Object): Promise<any> {
    return new Promise(async (resolve, reject)=>{
      for (const key in objectCustomer) {
        const data = objectCustomer[key];
        if ( typeof data == "object") {
          const dataSet = await this.replaceLenguage(data, objectDefault[key]);
          objectDefault[key] = dataSet
        }else{
          objectDefault[key] = data;
        }
      }
      resolve(objectDefault);
    });
  }

  public async getLanguage(language): Promise<any>{
    return new Promise(async (resolve, reject)=>{
  
      await this.languageService.getLanguage(language)
      .then((response) => {
        resolve(response);
      })
      .catch((e) => {
        console.log(e);
      }); 
    });
  }
}
