<div class="wrapper" id="chatScroll" #scrollMe [scrollTop]="scrollMe?.scrollHeight">
  <div class="_chat">

    <div class="row" *ngIf="btnMoreMsg && messages.length > 0">
      <button type="button" class="btn btn-moreMsg" [ngbTooltip]="jsonLanguage?.web?.default?.components?.chat?.previus_msg_tooltip ||'Ver mensajes anteriores'" placement="bottom"
        (click)="moreMessages()">Ver más</button>
    </div>

    <!-- MENSAGE DEFAULT -->
    <ng-container *ngIf="defaultBanner">
      <app-chat-notice class=" _animate" [item]="defaultBanner"></app-chat-notice>
    </ng-container>
    <!-- MENSAGE DEFAULT -->
    <ng-container *ngFor="let item of messages">
      <ng-container *ngIf="item.type == 'MESSAGE'">
        <div class="bubble _animate"
          [class.self]="isMyMessage(item.user._id) || (private && guestId !== item.user._id)">
          <!--validar que el usuario sea yo-->
          <div class="head" title="Ver" (click)="showUserInfo(item); openModal(modalAssistance, 'sm');">
            <div class="img">
              <img [src]="item.user.image | photoGuest" alt="">
            </div>
            <span>{{isMyMessage(item.user._id) || (private && guestId !== item.user._id) ? (jsonLanguage?.web?.default?.components?.chat?.me_label  || "Yo") : ((item?.user?.name | decryptGuestInfo) || (item?.user?.name | decrypt)) }} &bull; {{item.date | moment:'ll - hh:mm A'}}</span>
          </div>
          <div class="text">{{item.text | decryptChat: chatSelected?.key}}</div>
        </div>
      </ng-container>

      <ng-container *ngIf="item.type == 'NOTICE'">
        <app-chat-notice [item]="item"></app-chat-notice>
      </ng-container>
    </ng-container>
  </div>
</div>

<ng-template #modalAssistance let-modal>
  <div class="modal-body p-0">
    <div class="row rowCard">
      <div class="img bg-primary d-flex">
        <i *ngIf="!userData?.image" class="fa fa-user fa-4x m-auto text-white"></i>
        <img *ngIf="userData?.image" [src]="userData?.image | photoGuest" alt="">
      </div>
      <div class="top text-center pb-2">
        <h3 class="mb-1 d-flex justify-content-center lh-5">
          <i class="fa fa-star text-warning tx-18 my-auto lh-1"
          *ngIf="userData?.category == 'VIP'"></i> 
          {{((userData?.type !== "INVITADO") ? (userData?.name | decrypt) :  (userData?.name | decryptGuestInfo)) || "..."}}</h3>
        <span class="text-primary tx-16">{{userData?.type || "..."}}</span>
      </div>
      <div class="info border-top">
        <span *ngIf="userData?.type != 'ADMIN'"> <b>{{jsonLanguage?.web?.default?.components?.chat?.modal_message_info?.user_email || "Correo"}}:</b>   {{((userData.type !== "INVITADO") ? (userData?.email | decrypt) : (userData?.email | decryptGuestInfo)) || "..."}} </span>
        <span *ngIf="userData?.type != 'ADMIN'"> <b>{{jsonLanguage?.web?.default?.components?.chat?.modal_message_info?.user_phone || "Teléfono"}}:</b> {{((userData.type !== "INVITADO") ? (userData?.phone | decrypt) : (userData?.phone | decryptGuestInfo)) || "..."}} </span>
        <span *ngIf="userData?.type === 'INVITADO'"> <b>{{jsonLanguage?.web?.default?.components?.chat?.modal_message_info?.user_company ||  "Empresa"}}:</b>  {{userData?.companyName || "..."}}</span>
        <span *ngIf="userData?.type === 'INVITADO'"> <b>Puesto:</b>   {{userData?.position || "..."}}</span>
        <span > <b>{{jsonLanguage?.web?.default?.components?.chat?.modal_message_info?.date_label  || "Fecha y hora:"}}</b>  {{userData.date | moment:'ll - hh:mm A'}}</span>
      </div>
    </div>
  </div>
  
  <div class="modal-footer justify-content-between ">
    <button class="btn btn-danger" (click)=" modal.dismiss(); userData = null;">
      {{jsonLanguage?.web?.default?.components?.chat?.modal_message_info?.close_btn_label  || "Cerrar"}}
    </button>
  </div>
</ng-template>