<form [formGroup]="hostForm">
    <div class="card-body border-top" style="padding: 0 15px;">
        <div class="row">
          <div class="col-md-4">
            <h6>{{jsonLanguage?.web?.default?.components?.events?.new_event_modal?.host_step?.img_host_label || "Imagen del Host"}} <span class="text-danger">*</span></h6>
            <div class="_hostImg border overflow-hidden">
              <img [src]="imageHost[0].name | imagesLoad" alt=""> 
            </div>
            <small class="tx-danger" *ngIf="fieldisValid.fieldIsValid('image', hostForm)">{{jsonLanguage?.web?.default?.components?.events?.new_event_modal?.host_step?.img_host_validator  || "Campo requerido."}}</small>
            <div class="row">
              <div class="col-md-12">
                <div class="input-group file-browser" placement="top" [ngbTooltip]="jsonLanguage?.web?.default?.components?.events?.new_event_modal?.host_step?.img_select_tooltip || 'Tamaño máximo 5 MB. Dimensiones recomendadas 200px x 200px'" tooltipClass="tooltip-danger">
                  <label class="w-100 m-0">
                    <span class="btn btn-block btn-dark" *ngIf="manageEvents" >
                      <i class="fa fa-{{imageHost[0].name == null ? 'upload' : 'refresh'}} mr-1"></i> {{imageHost[0].name == null ? jsonLanguage?.web?.default?.components?.events?.new_event_modal?.host_step?.btn_img_label  || "Buscar Imagen" : jsonLanguage?.web?.default?.components?.events?.new_event_modal?.host_step?.btn_img_replace_label  || "Reemplazar"}}
                      <input accept=".jpg, .jpeg, .png" (change)="modalOptions.type == 'EDIT' && imageHost[0].name !== null ? onFileHostChanged(imageHost[0], $event, true) : onFileHostChanged(imageHost[0], $event)" type="file" style="display: none;">
                    </span>
                  </label>
                </div>
              </div>

              <div class="col-sm-12 mt-3">
                <h6>{{jsonLanguage?.web?.default?.components?.events?.new_event_modal?.host_step?.host_name_label || "Nombre del Host"}}<span class="text-danger">*</span></h6>
                <div class="input-group">
                  <input type="text" formControlName="name" class="form-control" [placeholder]="jsonLanguage?.web?.default?.components?.events?.new_event_modal?.host_step?.host_name_placeholder || 'Nombre del Host'" maxlength="100">
                </div>
                <small class="tx-danger" *ngIf="fieldisValid.fieldIsValid('name', hostForm)">{{jsonLanguage?.web?.default?.components?.events?.new_event_modal?.host_step?.host_name_validation || "Campo requerido.  Max. 100 caracteres"}}</small>
              </div>
              <div class="col-sm-12 mt-2">
                <h6>{{jsonLanguage?.web?.default?.components?.events?.new_event_modal?.host_step?.welcome_message_label || "Mensaje de bienvenida"}} <span class="text-danger">*</span></h6>
                <div class="input-group">
                  <textarea rows="4" class="form-control" formControlName="message" maxlength="250" [placeholder]="jsonLanguage?.web?.default?.components?.events?.new_event_modal?.host_step?.welcome_message_placeholder  || 'Mensaje para los invitados'"></textarea>
                </div>
                <small class="tx-danger" *ngIf="fieldisValid.fieldIsValid('message', hostForm)">{{jsonLanguage?.web?.default?.components?.events?.new_event_modal?.host_step?.welcome_message_validation || "Campo requerido.   Max. 250 caracteres"}}</small>
              </div>
              <div class="col-sm-12 mt-2">
                <h6>{{jsonLanguage?.web?.default?.components?.events?.new_event_modal?.host_step?.host_phone_label || "Teléfono de contacto"}} <span class="text-danger">*</span></h6>
                <div class="input-group">

                  <div class="input-group-prepend">
                    <div class="input-group-text"><i class="fe fe-phone lh--9 op-6"></i></div>
                  </div>
                  <input type="text" class="form-control" formControlName="phone" maxlength="10" (keypress)="validatorsService.isInteger($event);"  [placeholder]="jsonLanguage?.web?.default?.components?.events?.new_event_modal?.host_step?.host_phone_placeholder || 'Teléfono'">
                </div>
                <small class="tx-danger" *ngIf="fieldisValid.fieldIsValid('phone', hostForm)">{{jsonLanguage?.web?.default?.components?.events?.new_event_modal?.host_step?.host_phone_validation || "Campo requerido.   Max. 10 caracteres"}}</small>
              </div>
              <div class="col-sm-12 mt-2 mb-3">
                <h6>{{jsonLanguage?.web?.default?.components?.events?.new_event_modal?.host_step?.host_email_label || "Correo de contacto"}} <span class="text-danger">*</span></h6>
                <div class="input-group">

                  <div class="input-group-prepend">
                    <div class="input-group-text"><i class="fe fe-mail lh--9 op-6"></i></div>
                  </div>
                  <input type="text" class="form-control" formControlName="email" maxlength="80" [placeholder]="jsonLanguage?.web?.default?.components?.events?.new_event_modal?.host_step?.host_email_placeholder || 'Correo'">
                </div>
                <small class="tx-danger" *ngIf="fieldisValid.fieldIsValid('email', hostForm)">{{jsonLanguage?.web?.default?.components?.events?.new_event_modal?.host_step?.host_email_validation  || "Campo requerido o formato invalido."}}</small>
              </div>
            </div>
          </div>
          <div class="col-md-5 border-left">
            <div class="row">
              <div class="col-sm-12">
                <h5 class="my-2">
                {{jsonLanguage?.web?.default?.components?.events?.new_event_modal?.host_step?.user_search_label || "Usuarios disponibles"}} <span class="text-danger">*</span>
              </h5></div>
              <div class="col-sm-12">
                <div class="input-group mb-2">
                  <div class="input-group-prepend">
                    <div class="input-group-text"><i class="fe fe-search lh--9 op-6"></i></div>
                  </div>
                  <input type="text" class="form-control" [placeholder]="jsonLanguage?.web?.default?.components?.events?.new_event_modal?.host_step?.user_search_placeholder  ||'Buscar...'" (keyup)="search($event)" #inputSearch>

                </div>
              </div>
            </div>
            <!-- LISTA DE USUARIOS -->
            <div class="border-top _listHeight">
              <div *ngFor="let user of userDataFilter" class="col-sm-12 border-bottom item">
                <div class="d-flex">
                  <div class="icon bg-primary"><i class="fa fa-user"></i></div>
                  <b>{{(user.name !== "") ?  user.name : user.email}}</b>
                </div>
                <div class="btn-group my-auto" *ngIf="manageEvents">
                  <button class="btn btn-sm border px-1 btn-primary" (click)="userAssign(user, 'HOST')"><b>{{jsonLanguage?.web?.default?.components?.events?.new_event_modal?.host_step?.host_btn_label || "Host"}}</b></button>
                  <button class="btn btn-sm border px-1 btn-tertiary" (click)="userAssign(user, 'STAFF')"><b>{{jsonLanguage?.web?.default?.components?.events?.new_event_modal?.host_step?.reception_btn_label || "Recepción"}}</b></button>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-3 border-left col-muted">
            <div class="row">
              <div class="col-sm-12">
                <h5 class="my-2 text-primary">
                  {{jsonLanguage?.web?.default?.components?.events?.new_event_modal?.host_step?.user_host_label || "Usuarios Host"}}
                </h5>
              </div>
            </div>
            <!-- LISTA DE USUARIOS -->
            <div class="border-top _listHeight ssmall" >
              <div class="col-sm-12 border-bottom item" *ngFor="let host of UsersHostList">
                <div class="d-flex">
                  <div class="icon bg-primary"><i class="fa fa-user"></i></div>
                  <b>{{(host.name !== "") ?  host.name : host.email}}</b>
                </div>
                <button *ngIf="manageEvents" class="btn btn-sm btn-danger my-auto" (click)="deleteAssign(host, 'HOST')"><i class="fa fa-trash"></i></button>
              </div>
            </div>
            <div class="row border-top">
              <div class="col-sm-12">
                <h5 class="my-2 text-tertiary">
                  {{jsonLanguage?.web?.default?.components?.events?.new_event_modal?.host_step?.user_reception_label || "Usuarios Recepción"}}
                </h5>
              </div>
            </div>
            <!-- LISTA DE USUARIOS -->
            <div class="border-top _listHeight ssmall" >
              <div class="col-sm-12 border-bottom item" *ngFor="let staff of UsersStaffList">
                <div class="d-flex">
                  <div class="icon bg-tertiary"><i class="fa fa-user"></i></div>
                  <b>{{(staff.name !== "") ?  staff.name : staff.email}}</b>
                </div>
                <button *ngIf="manageEvents" class="btn btn-sm btn-danger my-auto" (click)="deleteAssign(staff, 'STAFF')"><i class="fa fa-trash"></i></button>
              </div>
            </div>
          </div>
        </div>
    </div>
  </form>

  <div class="modal-footer justify-content-between">
    <button type="button" (click)="close()" class="btn btn-danger"> {{ (manageEvents) ? jsonLanguage?.web?.default?.components?.events?.new_event_modal?.host_step?.btn_cancel_label || "Cancelar" : jsonLanguage?.web?.default?.components?.events?.new_event_modal?.host_step?.btn_close_label || "Cerrar"}}</button>
    <div>
      <button  type="button" class="btn mr-3 btn-danger" *ngIf="manageEvents && this.modalOptions.type == 'EDIT' && !editHost" [swal]="swalDanger" (confirm)='deleteEvent()' name="custom-switch-checkbox"><i class="fa fa-trash mr-2"></i>{{jsonLanguage?.web?.default?.components?.events?.new_event_modal?.host_step?.btn_delete_label||"Eliminar"}}</button>
      <button class="btn btn-dark mr-3" type="button" awPreviousStep><i class="fa fa-chevron-left mr-2"></i> {{jsonLanguage?.web?.default?.components?.events?.new_event_modal?.host_step?.btn_back_label||"Atrás"}}</button>
      
      <button type="button" (click)="Submit()" class="btn btn-success" *ngIf="manageEvents && this.modalOptions.type == 'EDIT' && !editHost" [disabled]="!hostForm.valid"><i class="fa fa-check mr-2"></i>{{jsonLanguage?.web?.default?.components?.events?.new_event_modal?.host_step?.btn_update_label || "Actualizar"}}</button>
      <button type="button" (click)="Submit()" class="btn btn-success flex-start" *ngIf="manageEvents && ((this.modalOptions.type == 'ADD' && !editHost) || this.modalOptions.type == 'CLONE')" [disabled]="!hostForm.valid"><i class="fa fa-check mr-2"></i>{{jsonLanguage?.web?.default?.components?.events?.new_event_modal?.host_step?.btn_save_label  || "Guardar"}}</button>
      <button type="button" (click)="Submit()" class="btn btn-success flex-start" *ngIf="manageEvents && this.modalOptions.type == 'EDIT'" [disabled]="!hostForm.valid"><i class="fa fa-check mr-2"></i>{{jsonLanguage?.web?.default?.components?.events?.new_event_modal?.host_step?.btn_update_label  ||"Actualizar"}}</button>

    </div>
  </div>

  <!-- <swal #swalDanger class="d-none" title="¿Estas seguro de eliminar el evento?" text="" icon="info" [showCancelButton]="true" [focusCancel]="true" [swalOptions]="{confirmButtonText: 'Aceptar', cancelButtonText: 'Cancelar'}"></swal> -->
