import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UrlValidService {

  constructor() { }

  //? Retorna un boleano true si es que la cadena contiene ya el protocolo http o https
  public async validUrlIsValid(url):Promise<boolean>{
    return new Promise((resolve) => {
      
      if (url) {
          let urlSplited = url.split("://")[0];

          if (urlSplited == "http" || urlSplited == "https") {
            resolve(true);
          }else{
            resolve(false);
          }
      }else{
        resolve(false)
      }
     
    })

  }
  //? Retorna una cadena con el protocolo a concatenar a la ruta si es que la consulta a dicho sitio fue exitosa;
  public async protocolIsValid(url:string):Promise<any>{
    return new Promise((resolve) => {
    if (url) {
      try {

        var request = new XMLHttpRequest();  
        request.open('GET', 'https://' + url, true);  
        request.send();  

        request.onload = () => {
          if(request.status === 200){
            resolve({protocol:"https://"})
          }else{
            resolve({protocol:"http://"})
          }
        }
      } catch (error) {
        resolve({protocol:undefined})
      }
    }else{
      console.log("No existe la ruta");
      resolve({protocol:undefined});
    }
   })

  }
}
