import { UserService } from 'src/app/shared/services/user.service';
import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-logo',
  templateUrl: './logo.component.html',
  styleUrls: ['./logo.component.scss'],
  host: {
    "[class.dark]": "userService?.login?.company?.darkLogo"
  }
})
export class LogoComponent implements OnInit {
  public logo: string = ''
  constructor(public userService: UserService) {
    this.logo = environment.apiUrl + userService?.login?.company?.logo || "../../../assets/img/brand/logoFeedbak.svg";
   }

  ngOnInit(): void {
  }

}
