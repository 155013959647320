import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppColorService } from '../../services/app-color.service';
import { GlobalAlertService } from '../../services/global-alert.service';

@Component({
  selector: 'app-full-layout',
  templateUrl: './full-layout.component.html',
  styleUrls: ['./full-layout.component.scss']
})
export class FullLayoutComponent implements OnInit {

  constructor(
    private router: Router,
    private globalAlert: GlobalAlertService ,
    public colors: AppColorService,
  ) { }

  ngOnInit(): void {
    
    // this.colors.setAll();
  
  // this.globalAlert.openAlert({
  //   title: "Feliz Cumpleaños",
  //   subtitle: "Disfruta este dia como un regalo de la vida, abraza a tus seres queridos y como pastel.",
  //   icon: "cake",
  //   layer: "confetti",
  //   background: "success",
  // })
  // this.globalAlert.openAlert(
  // {
  //   title: "Feliz Año nuevo",
  //   subtitle: "Gracias por dejarnos ser parte de una nuevo año en tu vida, disfruta al maximo y cumple tus metas.",
  //   icon: "confetti",
  //   layer: "fireworks",
  //   background: "dark",
  // })
  }

  mainSidebarOpen: any;
  hoverEffect($event, keepOpen = true) {
    if( $event?.type == 'mouseover' && keepOpen){
      this.mainSidebarOpen = 'main-sidebar-open';
      document.querySelector('body').classList.add('overflow-hidden')
    } else if($event?.x > 70 || !keepOpen){
      this.mainSidebarOpen = '';
      document.querySelector('body').classList.remove('overflow-hidden')
    }
  }
  
  clickonBody(){
    this.mainSidebarOpen = '';
    document.querySelector('body').classList.remove('overflow-hidden')
  }


}
