<div *ngIf="(userToken !== undefined && userToken !== null)">
 
  <div class="modal-header bg-dark">
    <h6 class="modal-title" id="modal-basic-title">{{jsonLanguage?.web?.default?.components?.change_credentials?.modal_title  || " Nuevas Credenciales"}}</h6>
    <button type="button" class="close" aria-label="Close" [title]="jsonLanguage?.web?.default?.components?.change_credentials?.close_btn_label  || 'Cerrar'" (click)="close()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">
    <div class="card custom-card border-0">
      <div class="card-body">
        <div class="row mb-4 ml-1">
          <h5>{{jsonLanguage?.web?.default?.components?.change_credentials?.welcome_text ||"¡Bienvenido! Para continuar, y por tu seguridad, por favor establece tus nuevas credenciales de acceso."}}</h5>
          <!-- Errores -->
          <div class="row row-lg mb-3 justify-content-center">
            <div *ngIf="(error !== null)" class="col-12 tx-danger tx-center">
              <small class="txt-error">{{error}}</small>
            </div>
          </div>
        </div>

        <form [formGroup]="credentialsForm" (ngSubmit)="submit()">
          <div class="row row-lg ">
            <!-- Control password -->
            <div class="col-lg-6 mb-4">
              <h6>{{jsonLanguage?.web?.default?.components?.change_credentials?.password_label || "Contraseña"}} <span class="spn-required">*</span></h6>
              <div class="input-group">
                <input class="form-control" formControlName="new_password" [title]="jsonLanguage?.web?.default?.components?.change_credentials?.password_tooltip_label || 'Nueva Contraseña'" [placeholder]="jsonLanguage?.web?.default?.components?.change_credentials?.password_placeholder || 'Ingresar Nueva Contraseña'" [type]="passTypeText ? 'text' : 'password'" (input)="onPasswordChange();" />
                <button (click)="toggleInputType('password')" class="btn btn-toogleType" type="button" [title]="passTypeText ? jsonLanguage?.web?.default?.components?.change_credentials?.hide_btn_tooltip  ||'Ocultar' : jsonLanguage?.web?.default?.components?.change_credentials?.view_btn_tooltip || 'Ver'">
                  <i class="mdi" [ngClass]="passTypeText ? 'mdi-eye-off' : 'mdi-eye'"></i>
                </button>
              </div>
              <div *ngIf="(formFields.new_password.touched ) && (formFields.new_password.errors)" class="tx-danger">
                <div *ngIf="formFields.new_password.errors?.required">
                  <small>{{jsonLanguage?.web?.default?.components?.change_credentials?.password_validator || "*Por favor, ingresa nueva contraseña."}}</small>
                </div>
              </div>
            </div>

            <!-- Control password confirm -->
            <div class="col-lg-6 mb-4">
              <h6>{{jsonLanguage?.web?.default?.components?.change_credentials?.confirm_password_label || "Confirmar Contraseña"}} <span class="spn-required">*</span></h6>
              <div class="input-group">
                <input class="form-control" formControlName="confirm_password" [title]="jsonLanguage?.web?.default?.components?.change_credentials?.confirm_password_tooltip_label || 'Confirmar Contraseña'"  [placeholder]="jsonLanguage?.web?.default?.components?.change_credentials?.confirm_password_placeholder || 'Confirmar Nueva Contraseña'" [type]="passTypeText ? 'text' : 'password'" (input)="onPasswordChange(); " />
              </div>

              <div *ngIf="(formFields.confirm_password.touched) && (formFields.confirm_password.errors)" class="tx-danger">
                <div *ngIf="formFields.confirm_password.errors?.required">
                  <small>{{jsonLanguage?.web?.default?.components?.change_credentials?.confirm_password_validator || "*Por favor, confirma nueva contraseña."}}</small>
                </div>
                <div *ngIf="errorConfirmPassword">
                  <small>{{jsonLanguage?.web?.default?.components?.change_credentials?.same_password_validator || "*Por favor, verifica que ambas contraseñas coincidan."}}</small>
                </div>
              </div>
            </div>

            <!-- Control Nip -->
            <div class="col-lg-6 mb-4">
              <h6>{{jsonLanguage?.web?.default?.components?.change_credentials?.nip_label || "NIP"}} <span class="spn-required">*</span></h6>
              <div class="input-group">
                <input class="form-control" formControlName="new_nip" [placeholder]="jsonLanguage?.web?.default?.components?.change_credentials?.nip_placeholder ||'Ingresar NIP'" [title]="jsonLanguage?.web?.default?.components?.change_credentials?.nip_tooltip || 'NIP'" min="0" [type]="nipTypeText ? 'text' : 'password'" oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);" maxlength="4" onkeypress='return event.charCode >= 48 && event.charCode <= 57' (input)="onNipChange(); " />
                <button (click)="toggleInputType('nip')" class="btn btn-toogleType" type="button" [title]="nipTypeText ? jsonLanguage?.web?.default?.components?.change_credentials?.hide_btn_tooltip || 'Ocultar' : jsonLanguage?.web?.default?.components?.change_credentials?.view_btn_tooltip ||  'Ver'">
                  <i class="mdi" [ngClass]="nipTypeText ? 'mdi-eye-off' : 'mdi-eye'"></i>
                </button>
              </div>
              <div *ngIf="(formFields.new_nip.touched ) && (formFields.new_nip.errors)" class="tx-danger">
                <div *ngIf="formFields.new_nip.errors?.required || formFields.new_nip.errors?.size">
                  <small>{{jsonLanguage?.web?.default?.components?.change_credentials?.nip_validator || "*Por favor, Ingresa Nip de 4 dígitos."}}</small>
                </div>
              </div>
            </div>

            <!-- Control Nip confirm -->
            <div class="col-lg-6 ">
              <h6>{{jsonLanguage?.web?.default?.components?.change_credentials?.confirm_nip_label || "Confirmar NIP"}} <span class="spn-required">*</span></h6>
              <input class="form-control" formControlName="confirm_nip" [placeholder]="jsonLanguage?.web?.default?.components?.change_credentials?.confirm_nip_placeholder ||  'Confirmar NIP'" [title]="jsonLanguage?.web?.default?.components?.change_credentials?.confirm_nip_tooltip || 'Confirmar NIP'" min="0" [type]="nipTypeText ? 'text' : 'password'" oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);" maxlength="4" onkeypress='return event.charCode >= 48 && event.charCode <= 57' (input)="onNipChange();" />
              <div *ngIf="(formFields.confirm_nip.touched ) && (formFields.confirm_nip.errors)" class="tx-danger">
                <div *ngIf="formFields.confirm_nip.errors?.required || formFields.confirm_nip.errors?.size">
                  <small>{{jsonLanguage?.web?.default?.components?.change_credentials?.confirm_nip_validator || "*Por favor, Confirma Nip de 4 dígitos."}}</small>
                </div>
                <div *ngIf="errorConfirmNip">
                  <small>{{jsonLanguage?.web?.default?.components?.change_credentials?.same_nip_validator ||  "*Por favor, verifica que ambos nip coincidan"}}.</small>
                </div>
              </div>
            </div>
          </div>
        </form>

      </div>
    </div>
  </div>

  <div class="modal-footer justify-content-between">
    <button type="button" class="btn btn-danger" (click)="close()">
      <i class="mdi mdi-cancel"></i> {{jsonLanguage?.web?.default?.components?.change_credentials?.cancel_btn_label || "Cancelar"}}
    </button>
    <button class="btn btn-success" type="submit" (click)="submit()" [disabled]="!credentialsForm.valid">
      <i class="mdi mdi-check"></i>{{jsonLanguage?.web?.default?.components?.change_credentials?.save_btn_label || "Guardar"}}
    </button>
  </div>
</div>