import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { GetEventByTokenService } from 'src/app/shared/services/get-event-by-token.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'event-card',
  templateUrl: './event-card.component.html',
  styleUrls: ['./event-card.component.scss'],
})
export class EventCardComponent implements OnInit {

  @Input() showDetail;
  @Input() defaultLanguage;
  @Input() jsonLanguage;
  public landinRoute = null;
  public tokenLinkId = null;
  public eventData = null;
  public imgurl;
  public isLoading:boolean = false;
  public eventDateFormated = "";

  statusColor = {
    created: "info",
    active: "danger",
    finished: "dark",
    postponed: "warning",
    canceled: "danger",
    deleted: "dark",
  }

  constructor(
    private geteventbyToken: GetEventByTokenService,
    private _Activatedroute:ActivatedRoute,
    private router: Router

  ) { 

    this._Activatedroute.paramMap.subscribe(async (params) => { 
      this.tokenLinkId = params.get('token');
      this.getEvent();   
    });

    this.getEvent();
  }

  ngOnInit(){
      this.landinRoute = this.router.url.split('/')[1] + "/";

  }

  ngOnChanges(changes: SimpleChanges){
      
    if (!changes?.defaultLanguage?.firstChange) {
      if (changes?.defaultLanguage?.currentValue) {
        if (changes?.defaultLanguage?.currentValue == "ESP") {
          this.defaultLanguage = moment(this.eventData?.event?.date).locale("es").format('LL')
        }else if(changes?.defaultLanguage?.currentValue == "ENG") {
          this.defaultLanguage = moment(this.eventData?.event?.date).locale("en").format('LL')
        }
      }  
    }
      if (!changes?.jsonLanguage?.firstChange) {
        if (changes?.jsonLanguage?.currentValue) {
          this.jsonLanguage = changes?.jsonLanguage?.currentValue
        }
      }
   
      
  }

  private async getEvent(){
    try {
      this.isLoading = true;
      this.eventData = await this.geteventbyToken.getEventByToken(this.tokenLinkId);      
      this.imgurl = environment.apiUrl + this.eventData.event?.image

    } catch (error) {
      console.log(error);
    }finally{
      this.isLoading = false;
    }
  }

  public gotoMap(){
    window.open('https://maps.google.com/?q='+this.eventData?.event.location.lat+ ','+ this.eventData?.event.location.lng, '_blank');
  }

  
}
