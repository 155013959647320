    <div class="img">
        <img [src]="(rawImages[index]?.index) ?  (( rawImages[index]?.name)| imagesLoad) : ((apiUrl + rawImages[index]?.image)| imagesLoad)"/> {{rawImages[index]?.image}}
    </div>
       
    <div class="info">
        <div class="p-2 border-bottom">
            <div class="mt-0 w-100 d-flex justify-content-between">
                <h2 class="mb-0">               
                    <span>{{activitie?.title | titlecase}}</span>
                </h2>
                <h2 class="d-flex flex-wrap mb-0">
                </h2>
            </div>
           
            <div class="d-flex justify-content-between">
                <div class="date text-secondary">
                    <span class="text-primary"><i class="fa fa-clock mr-2"></i> <b>
                        {{ activitie.startTime | moment:'l hh:mm A'}} - {{activitie.endTime | moment:'l hh:mm A'}}
                        </b> <span class="mx-2"></span></span>                
                </div>
            </div>
        </div>
    <div class="p-2 bg-muted">
        <ng-container *ngIf="detail">
            <div class="w-100" [innerHtml]="event?.description"></div>
            <div class="text-primary mt-2">
                <b>{{activitie?.description}}</b>
            </div>
        </ng-container>
        <div class="d-flex justify-content-between flex-wrap">
             <div class="d-flex">
                <!-- <div class="_pill badge-{{statusColor[activitie?.status | lowercase]}}-light ml-0 mr-2" title="Estatus"><span><i class="fa fa-circle"></i></span><span>{{activitie?.status | status}}</span></div>
                <div class="_pill badge-primary-light ml-0 mr-2" title="Duración"><span><i class="fa fa-clock"></i></span> <span> {{activitie?.duration}} mins.</span></div>
                <div class="_pill badge-dark-light ml-0 mr-2" title="# Invitados"><span><i class="fa fa-users"></i></span> <span><span *ngIf="detail">Capacidad:</span> {{activitie?.capacity}}</span></div> -->
            </div>
        
            <div class="d-flex flex-wrap" *ngIf="manageEvents && detail">

                <button class="btn btn-icon badge-primary-light text-primary mr-2"  (confirm)='deleteActivitie(item)' (click)="editActivitie(activitie)">
                    <i class="mdi mdi-pencil"></i>
                </button>
            
                <button class="btn btn-icon badge-danger-light text-danger" style="z-index: 3;" [swal]="swalDanger" (confirm)='deleteActivitie(activitie)'>
                    <i  class="fa fa-trash"></i>
                </button>
        
            </div>
         
        </div>
    </div>
    </div>
    
    <swal #swalDanger class="d-none" title="Estas seguro de eliminar esta actividad de la agenda?" text="" icon="info" [showCancelButton]="true" [focusCancel]="true" [swalOptions]="{confirmButtonText: 'Aceptar', cancelButtonText: 'Cancelar'}"></swal>
