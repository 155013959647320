import { Component, Input, OnInit } from '@angular/core';
import { UserService } from 'src/app/shared/services/user.service';

@Component({
  selector: 'app-chat-notice',
  templateUrl: './chat-notice.component.html',
  styleUrls: ['./chat-notice.component.scss'],
  host: {
    "[class.lines]": "separators"
  }
})
export class ChatNoticeComponent implements OnInit{

  @Input() item;
  @Input() separators = true;
  public jsonLanguage: any = {};

  constructor(
    private userService: UserService
  ) { }

  async ngOnInit() {
    this.jsonLanguage = this.userService?.user?.jsonLanguage
  }
}
