import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { WizardComponent } from 'angular-archwizard';
import { ValidateFormsDirective } from 'src/app/directives/validate-forms.directive';
import { AlertService } from 'src/app/shared/services/alert.service';
import { HttpService } from 'src/app/shared/services/http.service';
import { UserService } from 'src/app/shared/services/user.service';
import { environment } from 'src/environments/environment';
import { EventEditComponent } from '../../event-edit.component';

@Component({
  selector: 'event-invitation',
  templateUrl: './event-invitation.component.html',
  styleUrls: ['./event-invitation.component.scss']
})
export class EventInvitationComponent implements OnInit {
  
  @Input() manageEvents: boolean = true;
  @Input() modalOptions: any = null;
  @Input()  invitationpreview;
  @Input() eventSelected: any = null;
  @Output() onNext: EventEmitter<any> = new EventEmitter<any>();
  @Output() onClose: EventEmitter<boolean> = new EventEmitter<boolean>();

  @ViewChild(EventEditComponent, { static: false }) public wizard: WizardComponent;

  public invitationForm: FormGroup;
  public logo: string = "";
  public imageEvent = [{ index: 0, id: null, name: null, action: "" }];
  public removedImages: string[] = [];
  public regex = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/;
  public jsonLanguage: any = {};
  // public credentialDesign: boolean = true;
  
  constructor(
    public fieldisValid: ValidateFormsDirective,
    private userService: UserService,
    private toast: AlertService,
    private fb: FormBuilder,
    private httpService: HttpService,
  ) { 
    this.logo = environment.apiUrl + this.userService?.login?.company?.logo || "../../../../../assets/img/brand/K-fdbkWhite.svg";
  }

  ngOnChanges(changes: SimpleChanges){

    if(!changes?.eventSelected?.firstChange){
      this.detailInvitation(changes?.eventSelected?.currentValue?.invitationId, changes?.eventSelected?.currentValue?.image);
    }
  }

  ngOnInit(): void {    
    this.invitationForm = this.fb.group({
      webSite: [null, Validators.pattern(this.regex)],
      image: [null, Validators.required],
      type: [null],
      design: [true],
      colors: this.fb.group({
        primary: ["#111D5E"],
        secondary: ["#C70039"],
        tertiary: ["#F37121"],
      }),
      removedImages: []
    });

    this.jsonLanguage = this.userService?.user?.jsonLanguage;

  }

  public get c_primary(): AbstractControl {
    return this.invitationForm.controls['colors'].value.primary;
  }
  
  public get c_secondary(): AbstractControl {
    return this.invitationForm.controls['colors'].value.secondary;;
  }

  public get c_tertiary(): AbstractControl {
    return this.invitationForm.controls['colors'].value.tertiary;
    ;
  }

  public NextStep() {
    const isValid = this.invitationForm.valid
    this.invitationForm.markAllAsTouched()
    if (isValid) this.onNext.emit({ data: this.invitationForm.value, type: 'INVIT', valid: this.invitationForm.valid }); 
  }

  public onFileChanged(previous, event, remplace = false) {

    if (event.target.files[0].size < 6000000) {
      //Tomar imagen del input
      const FILE: File = event.target.files[0];
      // Imagen seleccionada
      const READER = new FileReader();
      READER.readAsDataURL(FILE);
      //Mostrar preview de la imagen y guardar en formulario.
      READER.onload = (event) => {
        this.imageEvent[previous.index].name = event.target.result as string;
      }
      this.invitationForm.get("image").setValue(FILE);

      if (remplace) {
        this.removedImages.push(previous.id)
        this.invitationForm.patchValue({ removedImages: this.removedImages })
      }
    } else {
      this.toast.info(this.jsonLanguage?.web?.default?.components?.events?.new_event_modal?.inv_step?.swal_img_size  || "La imagen debe ser menor a 5MB.",'');
      event.target.value = '';
    }
  }

  public async detailInvitation(id: any, image: any) {
    if (id) {
      try {
        const data = await this.httpService.get(`api/invitations/${id}`).toPromise();
        let Invitation = data;
  
        this.invitationForm.patchValue({
          title: Invitation.title,
          webSite: Invitation.webSite,
          colors: { primary: Invitation.colors.primary, 
            secondary: Invitation.colors.secondary, 
            tertiary: Invitation.colors.tertiary },
          event: Invitation.event,
          design: Invitation.design || false,
          type: Invitation.type,
          image: image
        });
  
        this.imageEvent[0].name = environment.apiUrl + image;
  
      } catch (error) {
        console.log(error);
      }
    }
  }

  public close(){
    this.onClose.emit();
  }

}
