import { HttpService } from "src/app/shared/services/http.service";
import { Injectable } from "@angular/core";

@Injectable({
  providedIn: 'root'
})

export class EventService {
  constructor(private http: HttpService) { }

  public addEvent = (data): Promise<any> => this.http.post(`api/events/addEvent`, data).toPromise();
  public editEvent = (data): Promise<any> => this.http.post(`api/events/editEvent`, data).toPromise();
  public getEvent = (id: string): Promise<any> => this.http.get(`api/events/${id}`).toPromise();
  public getInvitation = (id: string): Promise<any> => this.http.get(`api/invitations/${id}`).toPromise();
  public getSchedule = (id: string): Promise<any> => this.http.get(`api/schedules/${id}`).toPromise()
}