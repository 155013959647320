<div class="page main-signin-wrapper">
  <!-- Row -->
  <div class="row signpages overflow-hidden">
    <div class="col-sm-12 col-lg-5 d-none d-lg-flex details px-4">
      <div class="position-relative m-auto w-60">
        <img class="" src="../../assets/img/brand/logo-light.svg" alt="">
        <span class="d-block text-white mt-3 text-center op-8">{{jsonLanguage?.web?.default?.login?.welcome || "¡Bienvenid@!"}}</span>
      </div>
    </div>
    <div class="col-sm-12 col-lg-7 login_form ">
      <div class="card-body">
        <div class="row">
          <div class="col-sm-12">
            <img src="../../assets/img/brand/logo.svg" class="d-lg-none header-brand-img float-left mb-4" alt="logo">
            <div class="clearfix"></div>
          </div>
        </div>
        <form [formGroup]="loginForm" (ngSubmit)="Submit()" novalidate>
          <div class="row">
            <div class="col-12">
              <h4 class="text-left mb-1">{{jsonLanguage?.web?.default?.login?.login || "Iniciar sesión"}}</h4>
              <p class="mb-4 text-muted tx-13 ml-0 text-left">{{jsonLanguage?.web?.default?.login?.subtitle || "Ingresa al administrador de"}} <b class="text-primary">Link&Meet</b>.</p>
              <ngb-alert type="primary" [dismissible]="true" *ngIf="expiredSesionAlert">
                <span class="alert-inner--text">Tu sesión ha expirado, por favor ingresa nuevamente.</span>
              </ngb-alert>
              <ngb-alert type="danger" [dismissible]="true" *ngIf="invalidCredentials">
                <span class="alert-inner--text">Lo sentimos, las credenciales que ingresaste son inválidas.</span>
              </ngb-alert>
            </div>
          </div>
          <div class="row mb-3">
            <div class="col-12">
              <h6>{{jsonLanguage?.web?.default?.login?.user_field || "Usuario"}}</h6>
              <input class="form-control" formControlName="username" type="text" [placeholder]="jsonLanguage?.web?.default?.login?.user_placeholder || 'Ingresa Usuario'">
              <div *ngIf="(loginFormControl.username.touched || submitted) && (loginFormControl.username.errors?.required)" class="tx-danger">
                <small>{{jsonLanguage?.web?.default?.login?.user_validation || "Por favor, ingresa un usuario válido"}}</small>
              </div>
            </div>
          </div>
          <div class="row mb-2">
            <div class="col-sm-12 col-md-6 col-lg-8">
              <h6>{{jsonLanguage?.web?.default?.login?.password_field || "Contraseña"}}</h6>
              <input class="form-control" formControlName="password" type="password"
                [placeholder]="jsonLanguage?.web?.default?.login?.password_placeholder || 'Ingresa Contraseña'">
            </div>
            <div class="col-sm-12 col-md-6 col-lg-4">
              <h6>{{jsonLanguage?.web?.default?.login?.nip_field || "NIP"}}</h6>
              <input class="form-control" formControlName="nip" maxlength="4" type="password" placeholder="****">
            </div>
          </div>
          <div class="col-12 p-0 mb-3">
            <ng-container *ngIf="((loginFormControl.password.touched || submitted) && (loginFormControl.password.errors?.required)) || ((loginFormControl.nip.touched || submitted) && (loginFormControl.nip.errors?.required))">
              <small class="text-danger">{{jsonLanguage?.web?.default?.login?.nip_validation || "Por favor, ingresa tu"}}
                <b *ngIf="(loginFormControl.password.touched || submitted) && (loginFormControl.password.errors?.required)">{{jsonLanguage?.web?.default?.login?.password_field || 'contraseña'}}</b>
                <span class="mx-1" *ngIf="((loginFormControl.password.touched || submitted) && (loginFormControl.password.errors?.required)) && ((loginFormControl.nip.touched || submitted) && (loginFormControl.nip.errors?.required))">{{jsonLanguage?.web?.default?.login?.nip_concat_text || 'y'}}</span>
                <b *ngIf="(loginFormControl.nip.touched || submitted) && (loginFormControl.nip.errors?.required)">{{jsonLanguage?.web?.default?.login?.nip_field || 'NIP'}}</b>
              </small>
            </ng-container>
          </div>
          <div class="row">
            <div class="col-6 pr-5 d-none d-lg-flex">
              <img src="../../assets/img/brand/logoFeedbak.svg" class="img-responsive my-auto">
            </div>
            <div class="col-sm-12 col-lg-6 pl-lg-0">
              <button class="btn ripple btn-primary btn-block" type="submit" [disabled]="!loginForm.valid">{{jsonLanguage?.web?.default?.login?.login_btn_label || "Ingresar"}}</button>
            </div>
            <div class="col-sm-12 d-flex d-lg-none pt-4">
              <img src="../../assets/img/brand/logoFeedbak.svg" style="width: 120px;" class="img-responsive mx-auto">
            </div>
          </div>
          <div class="row pt-3">
            <div class="col-sm-12 text-center text-lg-left text-primary" (click)="openModalRecovery(modalRecovery)">
              <span class="cursor-pointer">{{jsonLanguage?.web?.default?.login?.forgotten_credentials || "Reestablecer Credenciales"}}</span>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
  <app-footer>
      <div class="text-primary mb-1">
        <ng-container *ngFor="let item of loginData?.company?.languages || []">
          <a class="mx-2" href="javascript:void(0);" (click)="changeLanguage(item)" [style.text-decoration]="selectedLanguage?.code == item?.code ? 'underline' : 'none'">({{item?.code | uppercase}}) {{item?.longname}}</a>
        </ng-container>
        <ng-container *ngIf="!loginData?.company?.languages?.length"><span class="text-dark op-5">Sin idiomas disponibles</span></ng-container>
      </div>
  </app-footer>
  <!-- End Row -->
  <ng-template #modalCredentials let-modal>
    <div>
      <modal-credentials [idUser]="idUser" [userToken]="userToken" (onClose)="modal.close(); close()" (reset)="reset()" (onSave)="onSave()"></modal-credentials>
    </div>
  </ng-template>

  <ng-template #modalRecovery>
    <modal-recovery [envData]="envData" [envKey]="envKey" [companyData]="companyData" (onClose)="modalService.dismissAll()">
    </modal-recovery>
  </ng-template>