import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ValidateFormsDirective } from 'src/app/directives/validate-forms.directive';
import { DecryptPipe } from 'src/app/shared/pipes/decrypt.pipe';
import { AlertService } from 'src/app/shared/services/alert.service';
import { EventService } from 'src/app/shared/services/event.service';
import { HttpService } from 'src/app/shared/services/http.service';
import { UserService } from 'src/app/shared/services/user.service';
import { ValidatorsService } from 'src/app/shared/services/validators.service';
import { environment } from 'src/environments/environment';
import { textChangeRangeIsUnchanged } from 'typescript';

@Component({
  selector: 'event-host',
  templateUrl: './event-host.component.html',
  styleUrls: ['./event-host.component.scss']
})
export class EventHostComponent implements OnInit {

  @Input() manageEvents: boolean = true;
  @Input() modalOptions: any = null;
  @Input() eventSelected: any;
  @Input() editHost:boolean; 

  @Output() onNext: EventEmitter<any> = new EventEmitter<any>();
  @Output() onSave: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() onClose: EventEmitter<boolean> = new EventEmitter<boolean>();

  @ViewChild('inputSearch') inputSearch: any;

  public hostForm: FormGroup;
  public imageHost = [{ index: 0, id: null, name: null, action: "" }];
  private re = /^[a-zA-Z0-9\._\-]+@[a-z0-9.-]+\.[a-z]{2,3}$/;  // Validar email
  public userDataFilter = [];
  public UsersHostList: any[] = [];
  public usersData: any;
  public UsersStaffList: any[] = [];
  public removedImages: string[] = [];
  public jsonLanguage: any = {};
  constructor(
    private fb: FormBuilder,
    public fieldisValid: ValidateFormsDirective,
    public validatorsService: ValidatorsService,
    private toast: AlertService,
    private httpService: HttpService,
    private decrypt: DecryptPipe,
    private eventService: EventService,
    private router: Router,
    public userService: UserService

  ) { }

  async ngOnChanges(changes: SimpleChanges){
    if(!changes?.eventSelected?.firstChange){
      
      await this.detailHost(changes?.eventSelected?.currentValue?.hostId);
      let staffArray = changes?.eventSelected?.currentValue?.staff;
      let hostArray = changes?.eventSelected?.currentValue?.hostUsers

      
      if (this.modalOptions?.type != 'EDIT') {
        staffArray = [];
        hostArray = [];
      }

      await this.getUsers();
      
      if (this.usersData) {
        await this.TypeAssign(staffArray,hostArray);               
      }

    }    
      await  this.getListByTypeUser();

  }

  async ngOnInit() {
    this.hostForm = this.fb.group({
      name: [null, Validators.compose([Validators.required, Validators.maxLength(100)])],
      phone: [null, Validators.compose([Validators.required, Validators.maxLength(10)])],
      email: [null, Validators.compose([Validators.required, Validators.pattern(this.re)])],
      message: [null, Validators.compose([Validators.required, Validators.maxLength(250)])],
      image: [null, Validators.compose([Validators.required])],
      removedImages: []
    });
    this.jsonLanguage = this.userService?.user?.jsonLanguage;

      await this.getUsers();

  }

  public search(event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.userDataFilter = this.usersData.filter(user => { return !user.type })?.filter(user => { return user.name?.toLowerCase()?.includes(filterValue.toLowerCase()) });
    this.UsersHostList = this.usersData.filter(user => { return user.type == 'HOST' })?.filter(user => { return user.name?.toLowerCase()?.includes(filterValue.toLowerCase()) });
    this.UsersStaffList = this.usersData.filter(user => { return user.type == 'STAFF' })?.filter(user => { return user.name?.toLowerCase()?.includes(filterValue.toLowerCase()) });
  }

  public async getListByTypeUser() {
     
    let filterValue = this.inputSearch.nativeElement.value;
    this.userDataFilter = this.usersData ? this.usersData.filter(user => { return !user.type })?.filter(user => { return user.name?.toLowerCase()?.includes(filterValue.toLowerCase()) }) : [];
    this.UsersHostList =  this.usersData ? this.usersData.filter(user => { return user.type == 'HOST' })?.filter(user => { return user.name?.toLowerCase()?.includes(filterValue.toLowerCase()) }) : [];
    this.UsersStaffList = this.usersData ? this.usersData.filter(user => { return user.type == 'STAFF' })?.filter(user => { return user.name?.toLowerCase()?.includes(filterValue.toLowerCase()) }) : [];
    
  }

  public onFileHostChanged(previous, event, remplace = false) { // esta funcion se debe mover a un servicio
    if (event.target.files[0].size < 6000000) {
      //Tomar imagen del input
      let file: File = event.target.files[0];
      // Imagen seleccionada
      var reader = new FileReader();
      reader.readAsDataURL(file);
      //Mostrar preview de la imagen y guardar en formulario.
      reader.onload = (event) => {
        this.imageHost[previous.index].name = event.target.result as string;
      }
      this.hostForm.get("image").setValue(file);

      if (remplace) {
        this.removedImages.push(previous.id)
        this.hostForm.patchValue({ removedImages: this.removedImages })
      }
    } else {
      this.toast.info("La imagen debe ser menor a 5MB.", '');
      event.target.value = '';
    }
  }

  public Submit(){
    this.hostForm.markAllAsTouched()
    const isValid = this.hostForm.valid
    if (isValid) this.onNext.emit({ data: this.hostForm.value, type: 'HOST', usersData: this.usersData, UsersHostList: this.UsersHostList });
  }

  public async getUsers() {
    try {
      let data = await this.httpService.get(`api/users/list`).toPromise();
      data.forEach(element => {
        element.userName = this.decrypt.transform(element.userName);
        element.name = this.decrypt.transform(element.name);
        element.email = this.decrypt.transform(element.email);
      });
      this.usersData = data;
      
      this.userDataFilter = this.usersData.filter(user => { return !user.type });
      this.getListByTypeUser();
    } catch (error) {
      console.log(error);
    }
  }

  public userAssign(event: any, type: string) {
    
    this.usersData.find(user => { return user._id == event._id }).type = type;
    this.getListByTypeUser();
  }

  public deleteAssign(event, type: string) {
    this.usersData.find(user => { return user._id == event._id }).type = "";
    this.getListByTypeUser();
  }


  public async detailHost(id: any) {
    // Aqui consultamos la informacion del host
    try {
      const data = await this.httpService.get(`api/host/${id}`).toPromise();
      let eventHost = data.data;

      this.hostForm.patchValue({
        name: this.decrypt.transform(eventHost.name),
        phone: this.decrypt.transform(eventHost.phone),
        email: this.decrypt.transform(eventHost.email),
        image: eventHost.image,
        message: this.decrypt.transform(eventHost.message)
      })

      this.imageHost[0].name = environment.apiUrl + eventHost.image;

    } catch (error) {
      console.log(error);
    }

  }
    public async deleteEvent() {
    try {
      if (this.manageEvents) {
        await this.httpService.delete(`api/events/` + this.modalOptions._id).toPromise();
        await this.eventService.clearEventSelected();
        this.router.navigate(['/events']);
      }
      this.onSave.emit();//Update Events List

    } catch (error) {
      console.log(error);
    }
  }

  public close(){
    this.onClose.emit();
  }

  public async TypeAssign(staffArray,hostArray){
    await new Promise(async(resolve, reject) => {

      for await (const staff of staffArray) {
        this.usersData.find(user => { return user._id == staff }).type = "STAFF";
      }
      for (const host of hostArray) {
        this.usersData.find(user => { return  user._id == host }).type = "HOST";
      } 
      resolve(true);
    });
  }
}
