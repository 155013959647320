import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-global-chat',
  templateUrl: './global-chat.component.html',
  styleUrls: ['./global-chat.component.scss']
})
export class GlobalChatComponent implements OnInit {

  public banner = {
    title: "Bienvenido al chat para todos!" ,
    subtext: "En este espacio podras hablar con <b>TODOS</b> los asistentes de este evento, <u>se amigable</u>.",
    icon: "chatbubbles",
  };

  constructor() { }

  ngOnInit(): void {
  }

}
