import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-pagenotfound',
  templateUrl: './pagenotfound.component.html',
  styleUrls: ['./pagenotfound.component.scss']
})
export class PagenotfoundComponent implements OnInit {

  public flag: boolean = false;
  constructor() { }

  ngOnInit(): void {
    var URLactual = window.location.pathname;
    URLactual == "/event" ? this.flag = false : this.flag = true;
    
  }

}
