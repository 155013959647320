<div class="modal-header bg-dark">
  <h6 class="modal-title" id="modal-basic-title"> {{jsonLanguage?.web?.default?.components?.reset_credentials?.modal_title || "Reestablecimiento de Credenciales"}}</h6>
  <button type="button" class="close" aria-label="Close" [title]="jsonLanguage?.web?.default?.components?.reset_credentials?.close_label || 'Cerrar'" (click)="close()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">
  <div>
    <div class="card-body p-1">
      <div class="row mb-3 ml-1">
        <b>
          {{jsonLanguage?.web?.default?.components?.reset_credentials?.info_text_p1 || "Para reestablecer tu credenciales, por favor ingresa el correo electrónico que se encuentra ligado a tu cuenta."}} <br>
         {{jsonLanguage?.web?.default?.components?.reset_credentials?.info_text_p2 || " Si el correo que ingresaste se encuentra registrado con nosotros, te haremos llegar un email con las instrucciones para el cambio de tus credenciales."}}
        </b>
        <!-- Errores -->
        <div class="row row-lg mb-3 justify-content-center">
          <div *ngIf="(error !== null)" class="col-12 tx-danger tx-center">
            <small class="txt-error">{{error}}</small>
          </div>
        </div>
      </div>

      <form [formGroup]="recoveryForm" (ngSubmit)="submit()">
        <!-- Control email -->
        <div class="row row-lg ml-1 mr-1 mb-3">
          <h6>{{jsonLanguage?.web?.default?.components?.reset_credentials?.email_label || "Correo Electrónico "}}<span class="spn-required">*</span></h6>
          <div class="input-group">
            <input class="form-control" formControlName="email" [title]="jsonLanguage?.web?.default?.components?.reset_credentials?.email_label || 'Correo Electrónico'" [placeholder]="jsonLanguage?.web?.default?.components?.reset_credentials?.email_placeholder || 'Ingresar Correo Electrónico'" type="email" />
          </div>
          <div *ngIf="(formControls.email.touched ) && (formControls.email.errors)" class="tx-danger">
            <div *ngIf="formControls.email.errors?.required">
              <small>{{jsonLanguage?.web?.default?.components?.reset_credentials?.email_validator ||  "*Por favor, ingresa correo electrónico."}}</small>
            </div>
          </div>
        </div>
      </form>

    </div>
  </div>
</div>

<div class="modal-footer justify-content-between">
  <button matTooltip="Cerrar modal" type="button" class="btn btn-danger" (click)="close()"> {{jsonLanguage?.web?.default?.components?.reset_credentials?.cancel_button || "Cancelar"}} </button>
  <button matTooltip="Guardar cambios" class="btn btn-success" type="submit" (click)="submit()" [disabled]="!recoveryForm.valid"> {{jsonLanguage?.web?.default?.components?.reset_credentials?.acept_button  || "Reestablecer"}} </button>
</div>