import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';


@Injectable({
  providedIn: 'root'
})
export class QrscannerService {


  constructor(private http: HttpClient) { }

  /*getUsuarios(): Observable<any> {
    return this.http.get(this.url);

  }*/
}
