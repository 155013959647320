import { BrowserModule } from '@angular/platform-browser';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SharedModule } from './shared/shared.module';
import { RouterModule } from '@angular/router';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
//import { AgmCoreModule } from "@agm/core";

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { PipesModule } from './shared/pipes/pipes.module';
import { AuthenticationComponent } from './authentication/authentication.component';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { TokenInterceptorService } from './shared/services/token.interceptor.service';
import { ModalCredentialsComponent } from './authentication/components/modal-credentials/modal-credentials.component';
import { ModalRecoveryComponent } from './authentication/components/modal-recovery/modal-recovery.component';
import { NavService } from './shared/services/nav.service';
import { PortalComponentsModule } from './portal/components/portal-components.module';
 
@NgModule({
  declarations: [
    AppComponent,
    AuthenticationComponent,
    ModalCredentialsComponent,
    ModalRecoveryComponent,
    
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    NgbModule,
    RouterModule,
    SharedModule,
    CarouselModule,
    HttpClientModule,
    PipesModule,
    NgxDaterangepickerMd.forRoot(),
    PortalComponentsModule,
  ],
  providers: [{
    provide: HTTP_INTERCEPTORS,
    useClass: TokenInterceptorService,
    multi: true,
  }, NavService],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }
