import {Component, ViewChild, ViewEncapsulation, OnInit, Output, EventEmitter, Input, SimpleChanges} from '@angular/core';
import {QrScannerComponent} from 'angular2-qrscanner';
import { Subject } from 'rxjs';
import { QrscannerService } from 'src/app/actions-services/qrscanner.service';
import { AlertService } from 'src/app/shared/services/alert.service';


@Component({
  selector: 'app-qrscannercam',
  templateUrl: './qrscannercam.component.html',
  styleUrls: ['./qrscannercam.component.scss'],
  encapsulation: ViewEncapsulation.None,

})
export class QrscannercamComponent implements OnInit {

  @ViewChild(QrScannerComponent, { static : false }) qrScannerComponent: QrScannerComponent ;
  @Output() Qrdata: EventEmitter<any> = new EventEmitter<any>();
  @Output() deviceList: EventEmitter<any> = new EventEmitter<any>();
  @Input() deviceSelected: any;

  public chosenCameraSubject = new Subject();
  private deviceVideo: any;
  private choosenDev: any = null;


  constructor(
    private _qrscanservice: QrscannerService,
    public toast: AlertService) {
  }

  //Variables
  public qrcode;

  ngOnInit(): void {
    navigator.mediaDevices.getUserMedia({video: true }).then(async () => {
      let devices = await navigator.mediaDevices.enumerateDevices();
      this.listCameras(devices);
    }).catch(function(err) {
      console.log(err);
    });
  }

  ngOnChanges(changes: SimpleChanges){
      this.chosenCameraSubject.next(this.deviceVideo[changes?.deviceSelected?.currentValue || 0]);
  }

  public listCameras($event: MediaDeviceInfo[]):void {
    this.deviceVideo = $event.filter(device => device.kind === 'videoinput');
    this.choosenDev = null;
    for (const dev of this.deviceVideo){
        if (!dev.label.includes('front')){
            this.choosenDev = dev;
            break;
        }
    }
    if (this.choosenDev) {
      this.chosenCameraSubject.next(this.choosenDev);
    } else {
      this.chosenCameraSubject.next(this.deviceVideo[0]);
    }
    this.deviceList.emit({list: this.deviceVideo, selected: this.choosenDev});
  }

  ngAfterViewInit(): void{
    this.qrScannerComponent.capturedQr.subscribe(result => {
        this.qrcode = result;
        this.Qrdata.emit(this.qrcode);
    });
  }

}
