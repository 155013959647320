import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'portal-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  @Input() logoSrc: string;
  public logo: string;

  constructor() { }

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void{
    if(!changes.logoSrc.firstChange) if(this.logoSrc)this.logo = environment.apiUrl?.slice(0, -1) + this.logoSrc; else this.logo = "../../../../assets/img/brand/logo.svg";
  }

}
