import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PagenotfoundComponent } from "./components/pagenotfound/pagenotfound.component";
import { ErrorLayoutComponent } from './shared/layouts/error-layout/error-layout.component';
import { FullLayoutComponent } from './shared/layouts/full-layout/full-layout.component';
import { Pages_Routes } from './shared/routes/pages.routes';
import { AuthenticationComponent } from './authentication/authentication.component';
import { AuthGuard } from './shared/services/auth-guard.service';


const routes: Routes = [
  // LOGIN 
  { path: 'login', component: AuthenticationComponent },
  // PORTAL 
  { path: 'event/:token', loadChildren: () => import('./portal/pages/event/event.module').then(m => m.PortalEventModule) },
  { path: 'confirm/:token', loadChildren: () => import('./portal/pages/confirm/confirm.module').then(m => m.PortalConfirmModule ) },
  { path: 'reject/:token', loadChildren: () => import('./portal/pages/confirm/confirm.module').then(m => m.PortalConfirmModule ) },
  { path: 'feedback/:token', loadChildren: () => import('./portal/pages/feedback/feedback.module').then(m => m.PortalFeedbackModule) },
  // APP 
  { path: '', 
    canActivate:[AuthGuard], 
    component: FullLayoutComponent, children: Pages_Routes },
  { path: '**', component: PagenotfoundComponent },
];

@NgModule({
  imports: [[RouterModule.forRoot(routes, {
    anchorScrolling: 'enabled',
    scrollPositionRestoration: 'enabled',
    relativeLinkResolution: 'legacy'
  })],
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
