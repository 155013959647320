import { Component, EventEmitter, OnInit, Output, Input } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { CryptoSharkService } from '../../../shared/services/crypto-shark.service';
import { HttpService } from 'src/app/shared/services/http.service';
import { AlertService } from 'src/app/shared/services/alert.service';
import { UserService } from 'src/app/shared/services/user.service';

@Component({
  selector: 'modal-recovery',
  templateUrl: './modal-recovery.component.html',
  styleUrls: ['./modal-recovery.component.scss']
})
export class ModalRecoveryComponent implements OnInit {

  //Variables
  public recoveryForm: FormGroup;
  public error: string = null;
  public user: any = null;
  public errorConfirmPassword: boolean = false;
  public errorConfirmNip: boolean = false;
  public passTypeText: boolean = false;
  public nipTypeText: boolean = false;
  public validForm: boolean = false;
  public jsonLanguage: any = {};

  @Output() onSave: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() onClose: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() reset: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Input() envData: string = "";
  @Input() envKey: string = "";
  @Input() companyData = null;
  
  constructor(
    private fb: FormBuilder,
    private httpService: HttpService,
    private cryptoService: CryptoSharkService,
    public toast: AlertService,
    public userService: UserService
  ) { }

  ngOnInit(): void {
    this.recoveryForm = this.fb.group({
      email: new FormControl('', [
        Validators.required,
        Validators.email
      ])
    });

    this.jsonLanguage = this.userService?.user?.jsonLanguage;

  }

  get formControls() {
    return this.recoveryForm.controls;
  }

  async submit() {
    try {
      await  this.httpService.post(`api/users/resetCredentialsWEB`, {
        data:this.companyData.data,
        key: this.companyData.key,
        email: this.cryptoService.encryptAes(this.recoveryForm.controls['email'].value, this.companyData.key),
        company: this.cryptoService.encryptAes( this.companyData.subdomain, this.companyData.key),
      })
      .toPromise()
      .then( (response: any) => {
        this.close();    
        if(response?.message){ this.toast.successAlert(response.message,'',this.jsonLanguage?.web?.default?.components?.reset_credentials?.accept_btn_label || 'Aceptar') }
      })
      .catch((e) => {
        if(e?.error?.message){
          if(e?.error?.message ==="Este proceso es solo para usuarios administrativos de la plataforma."){ this.close() }
          this.toast.errorToast(this.jsonLanguage?.web?.default?.components?.reset_credentials?.process_admin_only || e.error.message);
        }
      })
    } 
    catch (error) { console.log(error) }
  }

  async close() {
    this.recoveryForm.reset();
    this.onClose.emit();
  }

}

