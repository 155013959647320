import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ValidateFormsDirective } from 'src/app/directives/validate-forms.directive';
import { AlertService } from 'src/app/shared/services/alert.service';
import { HttpService } from 'src/app/shared/services/http.service';
import { ValidatorsService } from 'src/app/shared/services/validators.service';
import Swal from 'sweetalert2'

@Component({
  selector: 'send-notification',
  templateUrl: './send-notification.component.html',
  styleUrls: ['./send-notification.component.scss']
})
export class SendNotificationComponent implements OnInit {
  
  @Output() onClose: EventEmitter<boolean> = new EventEmitter<boolean>();

    public NotificationForm: FormGroup
    public allSelected: boolean = true;
    public isLoading:boolean = false;
    public usersArray = [];
    public eventId = null;
    public usersTraslation = [];

    public isValid: boolean = false;

  constructor(
    private httpService: HttpService,
    private toast: AlertService,
    public validatorsService: ValidatorsService,
    private fb: FormBuilder,
    public fieldisValid: ValidateFormsDirective

  ) { }

  ngOnInit(): void {
    this.NotificationForm = this.fb.group({
      destination      : ['ALL', Validators.compose([Validators.required])],
      title     : ['', Validators.compose([Validators.required, Validators.maxLength(100)])],
      description  : ['', Validators.compose([Validators.required, , Validators.maxLength(250)])],
      types: this.fb.group({
        HOST: [{value: false, disabled:false}],
        STAFF: [{value: false, disabled:false}],
        GUEST: [{value: false, disabled:false}]
      })
    });

    this.eventId = sessionStorage.getItem("eventSelected");
    
  }

  public close(){
    this.onClose.emit();
  }

  public validType():string{
    let prueba = [];
    
    for (const key in this.NotificationForm.value.types) {
      const element = this.NotificationForm.value.types[key];
      
      if(element){
        prueba.push(key == 'ALL'? 'Todos' : key == 'STAFF' ? 'Recepcion' : key == 'GUEST' ? 'Invitados' : "HOST")
      }
  }
    return prueba.join(", ");
  }

  public async submit(){
    let valid = false;
    this.usersArray = [];

      if (!this.NotificationForm.valid) {
        this.NotificationForm.markAllAsTouched();
        this.toast.infoToast("Campos Incompletos");
        return;
      }
      
      for (const key in this.NotificationForm.value.types) {
        const element = this.NotificationForm.value.types[key];
        
        if(element){
          valid = true;
          this.usersArray.push(key);
        }
    }

      if (!valid) {
        this.toast.infoToast("Seleccione al menos 1 tipo de usuario a notificar");
        return;
      }

      try {
        Swal.fire({
          title: 'Estas seguro de enviar la notificaciones a ' + this.validType() + '?',
          icon: 'question',
          showDenyButton: true,
          confirmButtonText: 'Aceptar',
          denyButtonText: `Cancelar`,
        }).then((result) => {
          if (result.isConfirmed) {
            let params = { 
              eventId: this.eventId, 
              destination : this.usersArray, 
              title : this.NotificationForm.get("title").value,
              description: this.NotificationForm.get("description").value
              }
              this.isLoading = true;        
              this.httpService.post(`api/notifications/createAlert`, params).toPromise();
              this.toast.successAlert("Notificaciones Enviadas","");
              this.close();          
            }
        })
      
      } catch (error) {
        console.log(error);   
      }finally{
        this.isLoading = false;
      }
  }

  async onChange() {
    this.isValid = (this.NotificationForm.get("title").value?.trim() && this.NotificationForm.get("description").value?.trim());
  }

  async onBlur() {


    this.NotificationForm.patchValue({
      title: this.NotificationForm.get("title").value.trim(),
      description: this.NotificationForm.get("description").value.trim(),
       
    });
    
    this.onChange();
  }

}
