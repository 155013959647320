<div class="img" [class.border-bottom]="!detail">
    <img [src]="event?.image ? (event?.image | domSanitizer) : '' " />
</div>
<div class="info">
    <div class="p-2">
        <div class="mt-0 w-100 d-flex justify-content-between">
            <div class="my-auto pr-2">
                <h4 class="mt-0 mb-1"><b>{{event?.name | titlecase}}</b></h4>
                <span class="date text-secondary my-auto">
                    <i class="fa fa-calendar mr-2"></i><span> {{ event?.date | moment: "L - hh:mm A"}} </span>
                </span>
            </div>
            <h3 class="d-flex flex-wrap my-auto" [class.border-top]="!detail">
                <span class="badge badge-primary-light py-0 px-2 mb-auto tx-18">#{{event?.folio}}</span>
            </h3>
        </div>
    </div>
        <div class="p-2 bg-muted border-top">
            <ng-container *ngIf="detail">
                <div class="w-100 _desc" [innerHtml]="event?.description"></div>
                <small class="text-muted">{{jsonLanguage?.web?.default?.components?.home?.info_event_label || "Encima el mouse para ver texto completo"}}</small>
                <div class="my-2 text-secondary">
                    <b>{{jsonLanguage?.web?.default?.components?.home?.event_place_label || "Lugar"}}:</b> {{event?.location?.address}}
                </div>
            </ng-container>
            <div class="d-flex justify-content-between flex-wrap">
                <div class="d-flex justify-content-start" [class.w-100]="!detail">
                    <div class="_pill badge-{{statusColor[event?.status | lowercase]}} ml-0 mr-2" [title]="jsonLanguage?.web?.default?.components?.home?.event_status_label || 'Estatus'"><span><i
                                class="fa fa-circle"></i></span><span>{{event?.status | status}}</span></div>
                    <div class="_pill badge-primary ml-0 mr-2" [title]="jsonLanguage?.web?.default?.components?.home?.event_duration_label || 'Duración'"><span><i
                                class="fa fa-clock"></i></span> <span>{{event?.duration}} mins.</span></div>
                    <div class="_pill badge-dark ml-0 mr-0" [title]="jsonLanguage?.web?.default?.components?.home?.event_guest_label || '# Invitados'"><span><i
                                class="fa fa-users"></i></span> <span><span></span> {{event?.capacity}}</span>
                    </div>
                </div>
                <div class="d-flex flex-wrap" *ngIf="manageEvents && detail">
                    <button class="btn btn-icon badge-secondary-light text-secondary mr-2" style="z-index: 3;"
                        [title]="jsonLanguage?.web?.default?.components?.home?.clone_event_tooltip ||'Duplicar'" (click)='cloneEvent(event._id)'>
                        <i class="fa fa-copy"></i>
                    </button>

                    <button class="btn btn-icon badge-danger-light text-danger mr-2" style="z-index: 3;"
                        [title]="jsonLanguage?.web?.default?.components?.home?.delete_event_tooltip || 'Eliminar'" [swal]="swalDanger" (confirm)='deleteEvent(event._id)'>
                        <i class="fa fa-trash"></i>
                    </button>

                    <button *ngIf="(event?.status === 'ACTIVE' || event?.status === 'CREATED')" [title]="jsonLanguage?.web?.default?.components?.home?.edit_event_tooltip || 'Editar'"
                        class="btn btn-icon badge-primary-light text-primary" (click)='editEvent(event._id)'>
                        <i class="mdi mdi-pencil"></i>
                    </button>
                </div>

            </div>
        </div>
</div>

<swal #swalDanger class="d-none" [title]="jsonLanguage?.web?.default?.components?.home?.swal_delete_event  || '¿Estas seguro de eliminar el evento?'" text="" icon="question"
    [showCancelButton]="true" [focusCancel]="true"
    [swalOptions]="{confirmButtonText: jsonLanguage?.web?.default?.components?.home?.swal_confirm_btn_label  || 'Aceptar', cancelButtonText: jsonLanguage?.web?.default?.components?.home?.swal_cancel_btn_label  || 'Cancelar'}"></swal>