import { HttpService } from "src/app/shared/services/http.service";
import { Injectable } from "@angular/core";

@Injectable({
  providedIn: 'root'
})

export class UserService {
  constructor(private http: HttpService) { }


  public updateUser = (id: string, data): Promise<any> => this.http.put(`api/users/${id}`, data).toPromise();
  public getUser = (id): Promise<any> => this.http.get(`api/users/${id}`).toPromise();
  public createUser = (data): Promise<any> => this.http.post(`api/users/`, data).toPromise();
  public resetUser = (id, data): Promise<any> => this.http.put(`api/users/reset/${id}`, data).toPromise();

  public setLanguageUser = (data): Promise<any> => this.http.post(`api/users/changeLanguage/`, data).toPromise();
 // { language, jsonLanguage <- string} , 
}