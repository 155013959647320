import { Component, ElementRef, Input, OnInit, SimpleChange, SimpleChanges } from '@angular/core';
import { UserService } from 'src/app/shared/services/user.service';
import { environment } from 'src/environments/environment';
import { NavigationEnd, Router } from '@angular/router';
import { Menu, NavService } from '../../services/nav.service';
import { EventService } from '../../services/event.service';
// import { UserService } from '../../services/user.service';

@Component({
  selector: 'app-sidemenu',
  templateUrl: './sidemenu.component.html',
  styleUrls: ['./sidemenu.component.scss']
})
export class SidemenuComponent implements OnInit {

  @Input() isOpen: boolean = false;
  
  public menuItems: Menu[];
  public url: any;
  public logo: string;
  public eventSelected;

  constructor(
    private router: Router,
    private navServices: NavService,
    private userService: UserService,
    private eventService: EventService
  ) {
    this.logo = environment.apiUrl + userService?.login?.company?.logo || "../../../assets/img/brand/logoFeedbakWhite.svg";
    // this.logo = environment.urlAdmin + this.userService?.login?.company?.logo || "../../assets/img/brand/logo-light.svg"
    this.GetElements();
    this.eventSelected = this.eventService._event;
    
  }
  
  ngOnChanges() {
  }

  //Active NavBar State
  setNavActive(item) {
    // if (this.eventSelected?._id != '') {
      
      this.menuItems.filter(menuItem => {
        item.active = true;

        if (menuItem !== item) {
          menuItem.active = false;
          document.querySelector('.main-body').classList.remove('main-sidebar-show')    
        }
        if (menuItem.children && menuItem.children.includes(item)) {
          menuItem.active = true;
          
        }
        if (menuItem.children) {
          menuItem.children.filter(submenuItems => {
            if (submenuItems.children && submenuItems.children.includes(item)) {
              menuItem.active = true;
              submenuItems.active = true;
            }
          });
        }
      });

    // }
  }

 
  // Click Toggle menu
  toggleNavActive(item, turnAll = false) {

      if (!item.active) {
        this.menuItems.forEach(a => {
          if (this.menuItems.includes(item) || turnAll) {
            a.active = false;
          }
          if (!a.children) { return false; }
          a.children.forEach(b => {
            if (a.children.includes(item)) {
              b.active = false;
            }
          });
        });
      }
      item.active = !item.active;
    
 
    
  }

  ngOnInit(): void {    
  }

  public async GetElements(){  
    
    this.eventSelected = sessionStorage.getItem("eventSelected");
    
    this.navServices.getModules().subscribe(menuItems => {
      this.menuItems = menuItems;
      
      this.router.events.subscribe((event) => {
        if (event instanceof NavigationEnd) {
          menuItems.filter(items => {
            
            if (items.path === event.url) {
              this.setNavActive(items);
            }
            if (!items.children) { return false; }
            items.children.filter(subItems => {

              if (subItems.path === event.url) {
                this.setNavActive(subItems);
              }
              if (!subItems.children) { return false; }
              subItems.children.filter(subSubItems => {
                if (subSubItems.path === event.url) {
                  this.setNavActive(subSubItems);
                }
              });
            });
          });
        }
      });
    });
  }

  public siNo(){
  }

}
