import { Pipe, PipeTransform } from '@angular/core';
import { CryptoSharkService } from '../services/crypto-shark.service';
import { UserService } from '../services/user.service';


@Pipe({
  name: 'decryptGuestInfo'
})
export class DecryptGuestInfoPipe implements PipeTransform {

  constructor(private cryptoService: CryptoSharkService, private userService: UserService) {}

  transform(value: string): string {
      return value? this.cryptoService.decrypt(value, this.userService.login.company.dkg) : ''
  }
}
