import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AlertService } from '../../services/alert.service';
import { EventService } from '../../services/event.service';
import { HttpService } from '../../services/http.service';
import { LayoutService } from '../../services/layout.service';
import { NotificationsService } from '../../services/notifications.service';
import { UserService } from '../../services/user.service';
import { NotificationSidebarComponent } from '../notification-sidebar/notification-sidebar.component';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements OnInit {

  @Input() notifications: any[] = [];
  @Output() updateNotifications: EventEmitter<any> = new EventEmitter<any>()

  public eventNtf = null;
  public jsonLanguage: any = {};
  constructor
    (
      public httpService: HttpService,
      public notificationService: NotificationsService,
      public eventSelected: EventService,
      private router: Router,
      public layoutService: LayoutService,
      private alertService: AlertService,
      private userService: UserService

    ) { }

  ngOnInit(): void {
    this.jsonLanguage = this.userService?.user?.jsonLanguage;
  }

  async ngOnChanges() {
  }

  public async goToNotification(item: any, event: any) {

    //OBJETO DATA PARA RASTREAR EVENTO - CHAT
    if (item?.data) {

      //PERMISO PARA INGRESAR A MODULO CHAT
      if (this.userService?.user?.permissions?.messages?.active === true && item?.type == "PRIVATE-CHAT") {
        await this.detailEvent(item?.data?.eventId);
        
        //EVENTO EXISTENTE Y NO ELIMINADO
        if ((this.eventNtf) && this.eventNtf?.status !== "DELETED") {
          
          //CHAT PRIVADO Y SU ID
          if (item?.type == "PRIVATE-CHAT" && item?.data?.chatId) {
            
            //COMPARACION EVENTO CHAT Y EVENTO ACTUALMENTE SELECCIONADO
            if (item?.data?.eventId !== this.eventSelected._event._id) {
              await this.eventSelected.setEventSelected(item?.data?.eventId);
              this.alertService.warningToast(this.jsonLanguage?.web?.default?.components?.notifications?.selected_event_label || "Se ha cambiado de evento seleccionado para acceder al chat.");
            }
            
            // this.alertService.infoToast("Cargando, espere un momento");

            setTimeout(() => {
              window.location.replace(`/messages/${item?.data?.chatId}`);
              //this.router.navigateByUrl(`/messages/${item?.data?.chatId}`);
            }, 500);
          }
          // else if(item?.type == ""){

          // }
          else {
            this.alertService.errorToast(this.jsonLanguage?.web?.default?.components?.notifications?.chat_not_found_label  || "Chat no encontrado.");
          }
        }
        else {
          this.alertService.info(this.jsonLanguage?.web?.default?.components?.notifications?.inaccessible_chat_label || "Chat inaccesible, Este mensaje pertenece a un evento no disponible.",'');
        }
      }
      else {
        this.alertService.errorToast(this.jsonLanguage?.web?.default?.components?.notifications?.not_permission_chat_label || "No tienes permisos para acceder al apartado de chats.");
      }
    }

    this.layoutService.emitSidebarNotifyChange(event, null);
  }


  public async detailEvent(eventId: string = "") {
    try {
      await this.httpService.get(`api/events/${eventId}`).toPromise()
        .then(async (response: any) => {
          this.eventNtf = response?.data;
        }).catch(async (e) => {
          console.log(e);
          this.eventNtf = null;
        })

    } catch (error) {
      console.log(error);
      this.eventNtf = null;
    }
  }

}
