import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AppColorService {

  private _default_colors: IColors = { 
    primary: "#111D5E",
    secondary: "#C70039",
    tertiary: "#F37121",
  }

  private _colors: IColors = this._default_colors

  constructor() { }

  public get color(): IColors { return this._colors; }
  public set color(colors: IColors) { this._colors = colors; }
  public get defaultColors(): IColors { return this._default_colors }

  public set setPrimary(color: string){this._colors.primary = color;}
  public set setSecondary(color: string){this._colors.secondary = color;}
  public set setTertiary(color: string){this._colors.tertiary = color;}
  public set setAll(colors: string[]){
    this._colors.primary = colors[0]
    this._colors.secondary = colors[1];
    this._colors.tertiary = colors[2];
  }
  
}

interface IColors{
  primary: string,
  secondary: string,
  tertiary: string,
}
