import { LOCALE_ID, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { QrscannercamComponent } from './qrscannercam/qrscannercam.component';
import { NgQrScannerModule } from 'angular2-qrscanner';
import { PagenotfoundComponent } from './pagenotfound/pagenotfound.component';
import { PipesModule } from '../shared/pipes/pipes.module';
import { EventSelectComponent } from './event-select/event-select.component';
import { ChatContainerComponent } from './chat-container/chat-container.component';
import { ChatInputComponent } from './chat-input/chat-input.component';
import { GlobalChatComponent } from './global-chat/global-chat.component';
import { ModalEventSelectComponent } from './modal-event-select/modal-event-select.component';

import { CardEventComponent } from './modal-event-select/components/card-event/card-event.component';

import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MKLoaderComponent } from './mk-loader/mk-loader.component';

import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {MatIconModule} from '@angular/material/icon';
import { EventItemComponent } from './event-item/event-item.component';
import { EventGraphsComponent } from './event-graphs/event-graphs.component';
import { LogoComponent } from './logo/logo.component';
import { ChatNoticeComponent } from './chat-notice/chat-notice.component';
import { EventEditComponent } from './event-edit/event-edit.component';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { ArchwizardModule } from "angular-archwizard";
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AgmCoreModule } from '@agm/core';
import { GoogleMapsModule } from '@angular/google-maps';
import { GooglePlaceModule } from 'ngx-google-places-autocomplete';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { DecryptPipe } from 'src/app/shared/pipes/decrypt.pipe';
import { MkUserComponent } from './mk-user/mk-user.component';
import { EventInfoComponent } from './event-edit/components/event-info/event-info.component';
import { EventLocationComponent } from './event-edit/components/event-location/event-location.component';
import { EventInvitationComponent } from './event-edit/components/event-invitation/event-invitation.component';
import { EventHostComponent } from './event-edit/components/event-host/event-host.component';
import { SendNotificationComponent } from './send-notification/send-notification.component';
import { ScheduleItemComponent } from './schedule-item/schedule-item.component';

@NgModule({
  imports: [
    CommonModule,
    NgbModule,
    NgQrScannerModule,
    PipesModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatButtonToggleModule,
    MatIconModule,
    SweetAlert2Module.forRoot(), 
    ArchwizardModule,
    NgSelectModule,
    FormsModule,
    ReactiveFormsModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyB7AZ8E0BsiK2X10ituHHsFjSBLPUNz1Qk', 
      libraries: ['places']
    }),
    GoogleMapsModule,
    GooglePlaceModule,
    NgxMaterialTimepickerModule,
    NgxDaterangepickerMd.forRoot(),
    AngularEditorModule
  ],
  declarations: [
    QrscannercamComponent, 
    PagenotfoundComponent,
    GlobalChatComponent,
    ChatContainerComponent,
    ChatInputComponent,
    ModalEventSelectComponent,
    CardEventComponent,
    MKLoaderComponent,
    EventSelectComponent,
    EventItemComponent,
    EventGraphsComponent,
    LogoComponent,
    ChatNoticeComponent,
    EventEditComponent,
    MkUserComponent,
    EventInfoComponent,
    EventLocationComponent,
    EventInvitationComponent,
    EventHostComponent,
    SendNotificationComponent,
    ScheduleItemComponent
  ],
  exports: [
    QrscannercamComponent, 
    GlobalChatComponent,
    ChatContainerComponent,
    ChatInputComponent,
    ModalEventSelectComponent,
    CardEventComponent,
    MatButtonToggleModule,
    MatIconModule,
    MKLoaderComponent,
    EventSelectComponent,
    EventItemComponent,
    EventGraphsComponent,
    LogoComponent,
    ChatNoticeComponent,
    EventEditComponent,
    EventLocationComponent,
    MkUserComponent,
    SendNotificationComponent,
    ScheduleItemComponent
  ],
  providers:[{provide: LOCALE_ID, useValue: 'es'}, DecryptPipe]
})
export class ComponentsModule {}
