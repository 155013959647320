<div class="card custom-card card-event selectable" 
[ngClass]="(selected) ? 'event-selected' : ''">
    <div *ngIf="(mode === 'card'); else modeRow">
        <div class="row mode-card">
            <div class="div-img mb-3">
                <img [src]="event.image ? (event?.image | domSanitizer) : '' "
                    class="rounded-5 avatar-xxl image-event" alt="Image Event" />
            </div>

            <div class="div-info">
                <div class="row  border-bottom mb-2">
                    <div class="col-12 mt-1">
                        <h4 class="mt-0">{{event.name | titlecase}}</h4>
                    </div>
                    <div class="col-12">
                        <h6 class="mt-0 op-3">Folio : {{event.folio}}</h6>
                    </div>

                    <div class="col-12 op-5">
                        <i class="fe fe-calendar"></i><span> {{ event.date |
                            moment:
                            "ll - hh:mm A"}} </span>
                    </div>
                </div>

            </div>
            <div class="div-desc p-0 mb-3">

                <span class="op-7">
                    {{event.description}}
                </span>
            </div>
        </div>
    </div>

    <ng-template #modeRow>
        <div class="row mode-row">
            <div class="col-info col-12 col-xl-5">
                <div class="row">
                    <div class="col-3 mt-1">
                        <img [src]="event.image ? (event?.image | domSanitizer) : '' "
                            class="rounded-5 avatar-xl image-event" alt="Image Event" />
                    </div>

                    <div class="col-9">
                        <div class="row">
                            <div class="col-12 mt-1">
                                <h5 class="mt-0">{{event.name | titlecase}}</h5>
                            </div>
                            <div class="col-12">
                                <h6 class="mt-0 op-3">Folio : {{event.folio}}</h6>
                            </div>

                            <div class="col-12">
                                <i class="fe fe-calendar"></i><span> {{ event.date |
                                    moment:
                                    "ll - hh:mm A"}} </span>
                            </div>
                        </div>
                    </div>

                </div>


            </div>
            <div class="col-desc col-12 col-xl-7 p-0">
                <span class="op-7">
                    {{event.description}}
                </span>
            </div>
        </div>
    </ng-template>
</div>