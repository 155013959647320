import { Component, OnInit } from '@angular/core';
import { GlobalAlertService } from 'src/app/shared/services/global-alert.service';

@Component({
  selector: 'app-global-alert',
  templateUrl: './global-alert.component.html',
  styleUrls: ['./global-alert.component.scss'],
  host: {
    "[class.show]": "show"
  }
})
export class GlobalAlertComponent implements OnInit {

  public alerts: any = []
  public show: boolean = false;
  
  public index = 0;

  constructor(private service: GlobalAlertService) { 
    this.service.dataEvent
      .subscribe(res => {
        this.alerts.unshift(res);
        this.show = true;
      });
  }

  ngOnInit(): void {
    setInterval(() => {
      this.index < this.alerts.length-1 ? this.index++ : this.index = 0;
    }, 5000);
  }

  ngOnChanges(){
  }

  close(index: number){
    this.alerts.splice(index, 1);
    this.index = 0;
  }

}
