<!-- Main Header-->
<div class="main-header side-header sticky">

    <div class="container-fluid">
        <div class="main-header-left">
            <!-- <a class="main-header-menu-icon" href="javascript:void(0)" id="mainSidebarToggle" (click)="toggleMobileSidebar()">
                <img src="../../../../assets/img/K-fdbk.svg" alt="">
            </a> -->
            <!-- <app-event-select class="selectable" (click)="openModal(modalSelectEvent)"></app-event-select> -->
        </div>
        <div class="main-header-center">

            <app-global-alert></app-global-alert>

            <!--Mensaje de alerta!-->
            <!-- <ngb-alert type="danger" [dismissible]="false" style="margin-top: 12px; text-align: center;" *ngIf="userService.mensaje"> 
                <strong>{{userService.mensaje}}</strong>
            </ngb-alert>  -->
        </div>
        <div class="main-header-right">

            <div class="d-flex justify-content-end align-items-center">
                <!-- NOTIFICACIONES -->
                <div class="main-header-notification mr-3"
                    (click)="layoutService.emitSidebarNotifyChange($event, notifications); openNotifications()">

                    <a href="javascript:void(0)" class="nav-link icon">
                        <i class="fe fe-bell header-icons" ></i>
                        <span class="badge bg-danger text-white nav-link-badge" *ngIf="notificationService.unread != 0">{{notificationService.unread}}</span>
                    </a>
                </div>
                <!-- IDIOMA  -->
                <div class="main-header-notification flag-dropdown" ngbDropdown>
                    <a href="javascript:void(0)" class="nav-link icon country-Flag" ngbDropdownToggle>
                        <img src="../../../../assets/img/flags/{{LanguageSelected?.code}}.svg" alt="">
                    </a>
                    <div ngbDropdownMenu>
                        <!-- <div class="header-navheading">
                            <h6 class="main-notification-title">Idioma</h6>
                        </div> -->
                        <ng-container *ngFor="let item of LanguageList">
                            <a href="javascript:;" class="dropdown-item d-flex" (click)="changeLanguage(item)">
                                <span class="avatar mr-3 align-self-center bg-transparent"><img src="../../../../assets/img/flags/{{item?.code}}.svg"></span>
                                <div class="d-flex"><span class="mt-2">{{item?.longname}}</span></div>
                            </a>
                        </ng-container>
                    </div>
                </div>

                <!-- PERFIL -->
                <div ngbDropdown class="main-profile-menu mr-0">
                    <a ngbDropdownToggle class="d-flex" href="javascript:void(0)">

                        <img *ngIf="user?.image !== undefined && user?.image !== '' " [src]="(user?.image | domSanitizer)" alt="avatar"
                        class="rounded-circle avatar-md" />

                        <span *ngIf="user?.image === undefined || user?.image === '' " class="main-img-user">{{user?.shortName}}</span> 
                    </a>
                    <div ngbDropdownMenu>
                        <div class="header-navheading">
                            <h6 class="main-notification-title">{{user?.name}}</h6>
                            <p class="main-notification-text">{{user?.email}}</p>
                        </div>
                        <button type="button" ngbDropdownItem class="" (click)="signOutHandler()">
                            <i class="fe fe-power"></i> {{userService?.user?.jsonLanguage?.web?.default?.logout || "Cerrar Sesión"}}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Main Header-->

<div class="jumps-prevent" style="padding-top: 64px;"></div>

<ng-template #notifications>
    <app-notifications [notifications]="this.notificationService._Notifications"  (updateNotifications)="updateNotifications()"></app-notifications>
</ng-template>