import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { AlertService } from 'src/app/shared/services/alert.service';
import { EventService } from 'src/app/shared/services/event.service';
import { HttpService } from 'src/app/shared/services/http.service';
import { UserService } from 'src/app/shared/services/user.service';

@Component({
  selector: 'event-item',
  templateUrl: './event-item.component.html',
  styleUrls: ['./event-item.component.scss'],
  host: {
    class: "",
    "[class.vertical]": "!detail"
  }
})
export class EventItemComponent implements OnInit {

  @Input() event: any;
  @Input() detail: boolean = true;
  @Input() manageEvents: boolean = false;

  @Output() onUpdate: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() onClone: EventEmitter<any> = new EventEmitter<any>();
  @Output() onEdit: EventEmitter<any> = new EventEmitter<any>();

  public eventsList;
  public jsonLanguage:any = {};
  statusColor = {
    created: "info",
    active: "danger",
    finished: "dark",
    postponed: "warning",
    canceled: "danger",
    deleted: "dark",
  }

  constructor(
    private httpService: HttpService,
    private toast: AlertService,
    private eventService: EventService,
    private router: Router,
    private userService: UserService

  ) { }

  async ngOnInit() {
    this.jsonLanguage = this.userService?.user?.jsonLanguage
  }

  public async deleteEvent(event) {
    if (this.manageEvents) {
      try {
        this.eventsList = await this.httpService.delete(`api/events/` + event).toPromise();
        await this.eventService.clearEventSelected();
        this.router.navigate(['/events']);
        if (!this.detail) { this.onUpdate.emit(); }

      } catch (error) {
        console.log(error);
      }
    }
    else{
      this.onUpdate.emit();
    }
  }

  public cloneEvent(id:any){
    this.onClone.emit(id);
  }
  public editEvent(id:any){
    this.onEdit.emit(id);
  }
}
