import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GlobalAlertService {

  private data = new Subject<any>();
  public dataEvent = this.data.asObservable();

  public setData(config: any){
    this.data.next(config)
  }
  
  constructor() {}

  openAlert(config: any){
    this.data.next(config)
  }

  closeAlert(){this.data.next({})}
}
 