import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';

import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';


import { HttpService } from 'src/app/shared/services/http.service';
import { AlertService } from 'src/app/shared/services/alert.service';
import { TableService } from 'src/app/shared/services/table.service';
import { EventService } from 'src/app/shared/services/event.service';

@Component({
  selector: 'app-modal-event-select',
  templateUrl: './modal-event-select.component.html',
  styleUrls: ['./modal-event-select.component.scss']
})

export class ModalEventSelectComponent implements OnInit {

  //Variables
  public error: string = null;
  public eventoSeleccionado: string = null;

  events: any[] = [];

  public DT_events: MatTableDataSource<any>;
  public displayColumns: string[] = ['name'];
  public isLoading = true;

  public currentItemsToShow = [];
  public itemsPerPage = 8;
  public pageIndex = 0;


  @ViewChild('Paginator') paginator: MatPaginator;
  @ViewChild('Sort') sort: MatSort;

  @Output() onClose: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(
    public httpService: HttpService,
    public tableService: TableService,
    public eventService : EventService,
    public toast: AlertService) {

    this.DT_events = new MatTableDataSource;
  }

  async ngOnInit() {
    const id = sessionStorage.getItem('eventSelected');
    await this.eventService.setEventSelected(id);
    this.getEvents(); 
  }


 public async getEvents() {

    try {
      this.isLoading = true;
      await this.httpService.get(`api/events/list/getSelectableEvents`).toPromise()
        .then(async (response: any) => {
          this.events = response.events;
          this.DT_events.data = response.events;
          this.DT_events.paginator = this.paginator;
          this.DT_events.sort = this.sort;


          this.currentItemsToShow = this.DT_events.filteredData.slice(0, this.itemsPerPage);

        }).catch((e) => {
          console.log(e);
        })

    } catch (error) {
      console.log(error);
    }
  }

  async search(event: any) {

    const filterValue = (event.target as HTMLInputElement).value;
    this.DT_events.filter = filterValue.trim().toLowerCase();
    this.currentItemsToShow = this.DT_events.filteredData.slice(0, this.itemsPerPage);

    this.pageIndex = null;
  }

  onPageChange($event) {
    this.currentItemsToShow = this.DT_events.filteredData.slice($event.pageIndex * $event.pageSize, $event.pageIndex * $event.pageSize + $event.pageSize);
  }


  async seleccionarEvento(id: string) {
    this.eventService.setEventSelected(id);
    this.close();
  }

  async close() {
    
    this.onClose.emit();
  }
}