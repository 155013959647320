import { Component } from '@angular/core';
import * as moment from 'moment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'Eventos';
  public isSpinner = true;

  ngOnInit(): void {
    moment.locale('ES'); 
  }


}
