<div class="modal-body pt-0 border-top">
    <form [formGroup]="eventInfoForm">
          <div class="row mt-2">
            <div class="col-sm-12">
              <h6>{{jsonLanguage?.web?.default?.components?.events?.new_event_modal?.general_data_step?.event_title_label || "Título del evento"}} <span class="text-danger">*</span>  <small class="tx-danger ml-2" *ngIf="fieldisValid.fieldIsValid('name', eventInfoForm)">{{jsonLanguage?.web?.default?.components?.events?.new_event_modal?.general_data_step?.event_validation || "Campo requerido.   Max. 100 caracteres"}}</small></h6>
              <input class="form-control tx-22 font-weight-bold" formControlName="name" [placeholder]="jsonLanguage?.web?.default?.components?.events?.new_event_modal?.general_data_step?.event_title_placeholder || 'Título del evento'" type="text" maxlength="100" />
            </div>
          </div>
      <div class="row my-2">
        <div class="col-sm-2 pr-lg-0">
          <h6>{{jsonLanguage?.web?.default?.components?.events?.new_event_modal?.general_data_step?.init_date_label || "Fecha de Inicio"}} <span class="text-danger">*</span></h6>
          <div class="input-group date">
            <div class="input-group-prepend">
              <div class="input-group-text">
                <i class="fe fe-calendar lh--9 op-6"></i>
              </div>
            </div>
            <input type="text" 
              ngxDaterangepickerMd 
              [ngModelOptions]="{standalone: true}"
              [locale]="dateOptions" 
              [singleDatePicker]="true" 
              [(ngModel)]="selectedDate" 
              [autoApply]="true" 
              [minDate]="today" 
              (change)="changeDate($event)"
              [closeOnAutoApply]="false"  name="date" class="form-control" placeholder="DD/MM/YYYY" autocomplete="off" readonly  tabindex="-1"
              [disabled]="(this.modalOptions.type !== 'ADD' && !(manageEvents)) || blockEditing"
              />
            <!-- <small class="tx-danger" *ngIf="fieldisValid.fieldIsValid('date', eventInfoForm)">Campo requerido</small> -->
          </div>
        </div>
        <div class="col-md-3">
          <h6>{{jsonLanguage?.web?.default?.components?.events?.new_event_modal?.general_data_step?.init_time_label || "Hora de Inicio"}} <span class="text-danger">*</span></h6>
          <div class="input-group">
            <div class="input-group-prepend">
              <div class="input-group-text">
                <i class="fe fe-clock lh--9 op-6"></i>
              </div>
            </div>
              <ng-select [ngModelOptions]="{standalone: true}" class="form-control" placeholder="Horas" (change)="changeDateValidation('hour')" [disabled]="blockEditing"  [items]="hoursStart" bindLabel="value" bindValue="value"
              [clearable]="false" [(ngModel)]="hourSelectedStart"></ng-select>

              <ng-select [ngModelOptions]="{standalone: true}" class="form-control" placeholder="Minutos" (change)="changeDateValidation('hour')" [disabled]="blockEditing" [items]="minutesStart" bindLabel="label" bindValue="value"
              [clearable]="false" [(ngModel)]="minutesSelectedStart"></ng-select>

              <ng-select [ngModelOptions]="{standalone: true}" class="form-control" placeholder="AM/PM" (change)="changeDateValidation('hour')" [disabled]="blockEditing" [items]="momentdayStart" bindLabel="value" bindValue="value"
              [clearable]="false" [(ngModel)]="momentDaySelectedStart"></ng-select>
          </div>
        </div>
        <div class="col-md-4">
          <div class="row">
            <div class="col-md-4 pr-0">
              <h6>{{ jsonLanguage?.web?.default?.components?.events?.new_event_modal?.general_data_step?.duration_label  || "Duración"}}<span class="text-danger">*</span></h6>
              <div class="input-group">
                <input class="form-control" formControlName="duration" placeholder="0" type="text" maxlength="3" (keypress)="validIsNumeric($event)"/>
                <div class="input-group-append">
                  <div class="input-group-text">Mins</div>
                </div>
              </div>
              <small class="tx-danger" *ngIf="fieldisValid.fieldIsValid('duration', eventInfoForm)">{{jsonLanguage?.web?.default?.components?.events?.new_event_modal?.general_data_step?.duration_validation || "Campo requerido.  Max. 3 caracteres"}}</small>

            </div>
            <div class="col-md-4">
              <h6>{{jsonLanguage?.web?.default?.components?.events?.new_event_modal?.general_data_step?.capacity_label || "Capacidad"}} <span class="text-danger">*</span></h6>
              <input class="form-control" formControlName="capacity" [placeholder]="jsonLanguage?.web?.default?.components?.events?.new_event_modal?.general_data_step?.capacity_placeholder || 'Capacidad'" (keypress)="validIsNumeric($event)" type="text" maxlength="4"
              />
              <small class="tx-danger" style="white-space: nowrap;" *ngIf="fieldisValid.fieldIsValid('capacity', eventInfoForm)">{{jsonLanguage?.web?.default?.components?.events?.new_event_modal?.general_data_step?.capacity_validation || "Campo requerido.     Max. 9999"}}</small>
            </div>
            <div class="col-md-4 pl-0">
              <h6>{{ jsonLanguage?.web?.default?.components?.events?.new_event_modal?.general_data_step?.companions_label || "Acompañantes"}} <span *ngIf="validRequired" class="text-danger">*</span></h6>
              <div class="input-group">
                <div class="input-group-prepend">
                  <div class="input-group-text">
                    <label class="custom-switch cursor-pointer">
                      <input type="checkbox" name="custom-switch-checkbox" class="custom-switch-input"  
                      formControlName="partners" (change)="changeCheckValue($event.target.checked)" 
                      />
                      <span class="custom-switch-indicator"></span>
                    </label>
                  </div>
                </div>
                <input class="form-control" formControlName="maxPartners" [placeholder]="jsonLanguage?.web?.default?.components?.events?.new_event_modal?.general_data_step?.companions_placeholder || '#'" (keypress)="validIsNumeric($event)" type="text" maxlength="2"
                />
              </div>
              <small class="tx-danger" *ngIf="fieldisValid.fieldIsValid('maxPartners', eventInfoForm)">{{ jsonLanguage?.web?.default?.components?.events?.new_event_modal?.general_data_step?.companions_validation || "Campo requerido."}}</small>
            </div>
          </div>
        </div>

        <!-- <div class="col-sm-3">
          <h6>Web Site Login</h6>
          <input class="form-control" formControlName="extLink" placeholder="https://ejemplo.com" type="text" maxlength="150"/>
          <small class="tx-danger" *ngIf="fieldisValid.fieldIsValid('extLink', eventInfoForm)">Ingrese una url valida.</small>

        </div>  -->

      </div>
      <div class="row">

        <div class="col-sm-12">
          <h6>{{jsonLanguage?.web?.default?.components?.events?.new_event_modal?.general_data_step?.description_label || "Descripción"}} <span class="text-danger ml-2">*</span> <small class="tx-danger" *ngIf="fieldisValid.fieldIsValid('description', eventInfoForm)">{{jsonLanguage?.web?.default?.components?.events?.new_event_modal?.general_data_step?.description_validation || "Campo requerido."}}</small></h6>
          <angular-editor formControlName="description" [config]="config" [placeholder]=" jsonLanguage?.web?.default?.components?.events?.new_event_modal?.general_data_step?.description_placeholder || 'Descripcion del evento'"
          ></angular-editor>                  
        </div>
      </div>
  </form>
</div>

<div class="modal-footer justify-content-between">
    <button type="button" (click)="close()" class="btn btn-danger" > {{ (manageEvents) ? jsonLanguage?.web?.default?.components?.events?.new_event_modal?.general_data_step?.btn_cancel_label || "Cancelar" : jsonLanguage?.web?.default?.components?.events?.new_event_modal?.general_data_step?.btn_close_label || "Cerrar"}}</button>
    <button class="btn btn-dark" type="button" (click)="NextStep()" [disabled]="!eventInfoForm.valid">{{ jsonLanguage?.web?.default?.components?.events?.new_event_modal?.general_data_step?.btn_accept_label || "Siguiente"}}<i class="fa fa-chevron-right ml-2"></i></button>
</div>