<!-- <div class="modal-header">
    <h6 class="modal-title" id="modal-basic-title">
        Seleccionar evento
    </h6>

    <button type="button" class="close" aria-label="Close" title="Cerrar" (click)="close()">
        <span aria-hidden="true">&times;</span>
    </button>
</div> -->

<div class="modal-body pt-0">
    <div class="row border-bottom">
        <div class="col-4 p-3 d-flex">
            <i class="fa fa-calendar fa-2x my-auto text-primary"></i>
            <div class="d-flex flex-column ml-3 lh-1">
                <ng-container>
                    <h3 class="m-0">Eventos</h3>
                    <span class="text-muted">Selecciona el evento deseado.</span>
                </ng-container>
            </div>
        </div>
        <div class="col-4"></div>
        <div class="col-4 d-flex">
            <div class="input-group my-auto">
                <div class="input-group-prepend">
                    <div class="input-group-text">
                        <i class="fe fe-search lh--9 op-6"></i>
                    </div>
                </div>
                <input class="form-control" (keyup)="search($event)" type="text" placeholder="Buscar...">
            </div>
        </div>
    </div>
    <!-- Errores -->
    <div class="row row-lg justify-content-center">
        <div *ngIf="(error !== null)" class="col-12 tx-danger tx-center">
            <small class="txt-error">{{error}}</small>
        </div>
    </div>


    <div class="row">
        <div class="col-12">
        </div>
        <div class="col-12">
            <div class="row row-sm row-cnt-events">
                <div *ngFor="let event of currentItemsToShow" class="col"
                    [ngClass]="(group.value == 'row') ? 'col-12' : 'col-12 col-lg-6 col-xl-4 col-xxl-3'">
                    <card-event 
                    [event]="event" 
                    [mode]="group.value" 
                    [selected]="(event._id === eventService._event._id)"
                    (click)="seleccionarEvento(event._id)">
                    </card-event>
                </div>

                <div *ngIf="!currentItemsToShow?.length" class="col-12">
                    <div class="text-center text-muted my-3">Sin registros encontrados</div>
                </div>
            </div>
        </div>
    </div>
    <div class="row border-top">
        <div class="col-12">
            <mat-paginator 
            (page)="onPageChange($event)"
             [length]="10"
                [pageSize]="itemsPerPage" 
                aria-label="" showFirstLastButtons
                [pageIndex]="pageIndex"
                >
            </mat-paginator>
        </div>
    </div>
</div>