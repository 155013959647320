<div class="row m-0 border-bottom pointer-events-none">
    <div class="col-12 text-right">
    <h4 class="my-3 lh-9">{{jsonLanguage?.web?.default?.components?.notifications?.title  || "Notificaciones"}}</h4>
    </div>
  </div>
  <div class="w-100 d-flex flex-column">
    <ng-container *ngFor="let item of this.notificationService._Notifications">

      <div class="_notif border-bottom p-2 cursor-pointer" style="--primary: {{item?.event?.color}}" (click)="goToNotification(item, $event);">
        <div class="top d-flex flex-column mb-1">
        <b class="mb-1">
          <i class="fa fa-circle text-danger" *ngIf="!item.view"></i>
          <i class="fa fa-calendar mr-2 text-primary" *ngIf="item.type=='EVENT-COMMING' || item.type=='EVENT-START'"></i> <!-- ALERTA DEL EVENTO -->
          <i class="fa fa-comments mr-2 text-primary" *ngIf="item.type=='PRIVATE-CHAT'"></i> <!-- ALERTA DEL CHAT -->
          <i class="fa fa-star mr-2 text-warning" *ngIf="item.type=='ATTENDANCE'"></i> <!-- ALERTA VIP -->
          <span>{{item?.title || '...'}}</span>
        </b>
        <span>{{item?.description || '...'}}</span>
        </div>
        <div class="info d-flex justify-content-between">
          <!-- <span class="text-primary text-overflow pr-1">#{{item?.event?.folio}} - <b>{{item?.event?.title}}</b></span> -->
          <span class="text-muted whitespace-nowrap">{{item?.date | moment: "hh:mm A"}}</span>
        </div>
      </div>
      
    </ng-container>
  </div>