import { Component, EventEmitter, Input, OnInit, Output, ViewChild, ElementRef, NgZone, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ValidateFormsDirective } from 'src/app/directives/validate-forms.directive';
import { Icoordinates } from 'src/app/interfaces/icoordinates';
import { MapsAPILoader } from '@agm/core';
import { MouseEvent as AGMMouseEvent } from '@agm/core';
import { mapStyle } from 'src/app/shared/constants/mapConstants';
import { UserService } from 'src/app/shared/services/user.service';
@Component({
  selector: 'event-location',
  templateUrl: './event-location.component.html',
  styleUrls: ['./event-location.component.scss']
})

export class EventLocationComponent implements OnInit {

  @Input() modalOptions: any = null;
  @Input() manageEvents: boolean = true;
  @Input() eventSelected;

  @Output() onNext: EventEmitter<any> = new EventEmitter<any>();
  @Output() onClose: EventEmitter<boolean> = new EventEmitter<boolean>();

  public coordinates: Icoordinates = { lat: 32.521506, lng: -117.014576, zoom: 13 }
  public address;
  public locationForm: FormGroup;
  public mapStyle = mapStyle
  @ViewChild('searchGoogle')
  public searchElementRef: ElementRef;
  private geoCoder: any
  public jsonLanguage: any = {};
  constructor(
    public fieldisValid: ValidateFormsDirective,
    private fb: FormBuilder,
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone,
    public userService: UserService

  ) {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!changes?.eventSelected?.firstChange) {

      this.locationForm.patchValue({
        address: changes.eventSelected?.currentValue?.location?.address,
        references: changes.eventSelected?.currentValue?.location?.references,
        place: changes.eventSelected?.currentValue?.location?.place,
        lat: changes.eventSelected?.currentValue?.location?.lat,
        lng: changes.eventSelected?.currentValue?.location?.lng,
      });
      this.coordinates.lat = changes.eventSelected?.currentValue?.location?.lat;
      this.coordinates.lng = changes.eventSelected?.currentValue?.location?.lng;
      this.getAddress()
    }
  }

  ngOnInit(): void {
    this.jsonLanguage = this.userService?.user?.jsonLanguage;
    
    this.mapsAPILoader.load().then(() => {
      this.geoCoder = new google.maps.Geocoder;
      this.setCurrentLocation();
      const AUTOCOMPLETE = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement);
      AUTOCOMPLETE.addListener("place_changed", () => {
        this.ngZone.run(() => {
          const PLACE: google.maps.places.PlaceResult = AUTOCOMPLETE.getPlace();
          if (PLACE.geometry === undefined || PLACE.geometry === null) {
            return;
          }

          this.coordinates.lat = PLACE.geometry.location.lat();
          this.coordinates.lng = PLACE.geometry.location.lng();
        });
      });
    });

    this.mapForm();
  }

  private mapForm = () => {
    this.locationForm = this.fb.group({
      address: [null, Validators.compose([Validators.required, Validators.maxLength(500)])],
      references: [null, Validators.maxLength(500)],
      place: [null, Validators.compose([Validators.required, Validators.maxLength(100)])],
      lat: [this.coordinates.lat, Validators.compose([Validators.required])],
      lng: [this.coordinates.lng, Validators.compose([Validators.required])],
    })
  }

  private setCurrentLocation = (): void => {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.coordinates.lat = position.coords?.latitude;
        this.coordinates.lng = position.coords?.longitude;
      }
      );
    }
    this.getAddress()
  }

  public markerDragEnd = ($event: AGMMouseEvent): void => {
    this.coordinates.lat = $event.coords?.lat
    this.coordinates.lng = $event.coords?.lng
    this.getAddress();
  }

  private getAddress = async () => {
    try {
      await this.geoCoder.geocode({ 'location': this.coordinates }, (results: any[], status: string) => {
        if (status === 'OK') {
          if (results[0]) {
            this.address = results[0].formatted_address;
            this.locationForm.patchValue({
              address: this.address,
              lat: this.coordinates?.lat,
              lng: this.coordinates?.lng,
            })
          }
        }

      });
    } catch (error) {
      console.log(error)
    }
  }

  public async NextStep() {    
    this.locationForm.markAllAsTouched()
    const isValid = this.locationForm.valid
    if (isValid) { 
      await this.getAddress()
      this.onNext.emit({ data: this.locationForm.value, type: 'LOCATION' });
    }
  }

  public close() {
    this.onClose.emit();
  }

}
