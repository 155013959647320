<mk-loader *ngIf="isLoading" class="loader-spinner"></mk-loader>
<div class="modal-header">
  <h6 class="modal-title" id="modal-basic-title">{{returnModalName()}}</h6>
  <button type="button" class="close" aria-label="Close" (click)="close()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<!-- WIZARD FORM -->
<aw-wizard #wizard awNavigationMode [disableNavigationBar]="true" [defaultStepIndex]="modalOptions.step || 0">
  <!-- DATOS GENERALES -->
  <aw-wizard-step  [stepTitle]=" jsonLanguage?.web?.default?.components?.events?.new_event_modal?.gen_step_label || 'Datos generales'" [awCompletedStep]="isEdit || isClone || isClone">
    <event-info [modalOptions]="modalOptions" [countActivities]="countActivities" [(eventSelected)]=" eventSelected" (onClose)="close()" [manageEvents]="manageEvents" (onNext)="patchEventForm($event)"></event-info>
  </aw-wizard-step>
  <!-- UBICACION -->
  <aw-wizard-step   [stepTitle]="jsonLanguage?.web?.default?.components?.events?.new_event_modal?.ubi_step_label || 'Ubicación'" [awCompletedStep]="isEdit || isClone">
    <event-location [modalOptions]="modalOptions" [(eventSelected)]=" eventSelected" (onClose)="close()" [manageEvents]="manageEvents" (onNext)="patchEventForm($event)"></event-location>
  </aw-wizard-step>
  <!-- PERSONALIZACION -->
  <aw-wizard-step [stepTitle]="jsonLanguage?.web?.default?.components?.events?.new_event_modal?.inv_step_label  || 'Personalización'" [awCompletedStep]="isEdit || isClone">
    <event-invitation [modalOptions]="modalOptions" [(eventSelected)]=" eventSelected" (onClose)="close()" [manageEvents]="manageEvents" (onNext)="patchEventForm($event)" [invitationpreview]="invitationpreview"></event-invitation>
  </aw-wizard-step>
  <!-- RECEPCIÓN -->
  <aw-wizard-step [stepTitle]="jsonLanguage?.web?.default?.components?.events?.new_event_modal?.host_step_label || 'HOST & Recepción'" [awCompletedStep]="isEdit || isClone">
    <event-host [modalOptions]="modalOptions" [manageEvents]="manageEvents" [(eventSelected)]=" eventSelected" [editHost]="editHost" (onSave)="onSaveEmit()" (onClose)="close()" (onNext)="patchEventForm($event)"></event-host>
  </aw-wizard-step>
</aw-wizard>