import { Injectable, OnDestroy, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { until } from 'protractor';
import { BehaviorSubject, fromEvent, Subject } from 'rxjs';
import { takeUntil, debounceTime } from 'rxjs/operators';
import { UserService } from './user.service';


//Menu Bar
export interface Menu {
  headTitle?: string;
  title?: string;
  path?: string;
  icon?: string;
  img?: string;
  type?: string;
  badgeClass?: string;
  badgeValue?: string;
  active?: boolean;
  module?: string,
  children?: Menu[];
  section?:number,
  eventselected?: boolean,
  static?: boolean,
}

@Injectable({
  providedIn: 'root'
})
export class NavService implements OnDestroy {

  private unsubscriber: Subject<any> = new Subject();
  public screenWidth: BehaviorSubject<number> = new BehaviorSubject(window.innerWidth);

  public megaMenu: boolean = false;
  public megaMenuCollapse: boolean = window.innerWidth < 1199 ? true : false;
  public collapseSidebar: boolean = window.innerWidth < 991 ? true : false;
  private permissions: any;
  public eventSelected;
  public MENUITEMS : Menu[];
  public jsonLanguage: any = {};
  constructor(
    private router: Router,
    private users: UserService
  ) {
    this.setScreenWidth(window.innerWidth);
    fromEvent(window, 'resize').pipe(
      debounceTime(1000),
      takeUntil(this.unsubscriber)
    ).subscribe((evt: any) => {
      this.setScreenWidth(evt.target.innerWidth);
      if (evt.target.innerwidth < 991) {
        this.collapseSidebar = false;
        this.megaMenu = false;
      }

      if (evt.target.innerWidth < 1199) {
        this.megaMenuCollapse = true;
      }
    });
    if (window.innerWidth < 991) {
      this.router.events.subscribe(event => {
        this.collapseSidebar = false;
        this.megaMenu = false;
      })
    }
  }

  private setScreenWidth(width: number): void {
    this.screenWidth.next(width);
  }

  ngOnDestroy() {
    this.unsubscriber.next();
    this.unsubscriber.complete();
  }

  getModules(){
    this.eventSelected = sessionStorage.getItem('eventSelected');
    this.jsonLanguage = this.users?.user?.jsonLanguage;
    
     this.MENUITEMS = [
        { headTitle: 'Link&Meet', section:0},
        { path: '/events', title: this.jsonLanguage?.web?.default?.sidemenu?.l_m || 'Eventos', type: 'link', icon: 'fa fa-calendar', active: false, module: "manageEvents", section:0 },
        { headTitle: this.jsonLanguage?.web?.default?.sidemenu?.event_title || 'Evento', section:1, eventselected: true},
        { path: '/home', title: this.jsonLanguage?.web?.default?.sidemenu?.home || 'Inicio', type: 'link', img: 'home.svg', active: false, module: "home", section:1, eventselected: true },
        { path: '/messages', title: this.jsonLanguage?.web?.default?.sidemenu?.chat || 'Chat', type: 'link', img: 'chat1.svg', active: false, module: "messages", section:1 , eventselected: true},
        { path: '/community-chat', title: this.jsonLanguage?.web?.default?.sidemenu?.c_chat || 'Community Chat', type: 'link', img: 'chat2.svg', active: false, module: "community", section:1, eventselected: true },
        { path: '/assistance', title: this.jsonLanguage?.web?.default?.sidemenu?.assistance ||  'Asistencia', type: 'link', img: 'qr.svg', active: false, module: "assistance", section:1 , eventselected: true},
        // { path: '/invitations', title: 'Invitaciones ', type: 'link', img: 'fa fa-file-image', active: false, module: "invitations", section:1 },
        { path: '/score', title: this.jsonLanguage?.web?.default?.sidemenu?.summary || 'Resumen ', type: 'link', img: 'stars.svg', active: false, module: "score", section:1 },
        { headTitle: this.jsonLanguage?.web?.default?.sidemenu?.tracking_label || 'Seguimiento', section:2},
        { path: '/history', title: this.jsonLanguage?.web?.default?.sidemenu?.history || 'Historial', type: 'link', icon: 'fa fa-history', active: false, module: "history", section:2},

        { headTitle: this.jsonLanguage?.web?.default?.sidemenu?.account_label ||  'Cuenta', section:3},
        { path: '/users', title: this.jsonLanguage?.web?.default?.sidemenu?.users ||  'Usuarios ', type: 'link', icon: 'fa fa-users', active: false, module: "users", section:3 },
        { path: '/roles', title: this.jsonLanguage?.web?.default?.sidemenu?.rol ||  'Roles ', type: 'link', icon: 'fa fas fa-user-tag', active: false, module: "roles", section:3 },
        { path: '/supports', title: this.jsonLanguage?.web?.default?.sidemenu?.support ||  'Soporte ', type: 'link', icon: 'fa fa-question', active: false, module: "support", section:3 },
      ];

    this.permissions = this.users.user.permissions;
     if (this.users.user.roleName != "ADMIN") {
      
    //if (false) {
      //Elimina los modulos que se tiene permisos
       // se agrego un cambio a menu items que permite visualizar el modulo eventos, esto probablemente se podria refactorizar
       this.MENUITEMS = this.MENUITEMS.filter(menu => !menu.module || this.permissions[menu.module]?.access || this.permissions[menu.module]?.active || menu?.static);
      const index =  this.MENUITEMS.findIndex(menu => menu.module);
      this.MENUITEMS[index].active = true;

      //Elimina los titulos que no cuentan con modulo
      let hist = {};
      this.MENUITEMS.map( function (a) { if (a.section in hist) hist[a.section] ++; else hist[a.section] = 1; } );
      this.MENUITEMS =  this.MENUITEMS.filter(menu => hist[menu.section] > 1);
    }


    // if (this.eventSelected == null) {
    //   this.MENUITEMS =  this.MENUITEMS.filter(inv => {return !inv.eventselected})

    //   let hist = {};
    //   this.MENUITEMS.map( function (a) { if (a.section in hist) hist[a.section] ++; else hist[a.section] = 1; } );
    //   this.MENUITEMS =  this.MENUITEMS.filter(menu => hist[menu.section] > 1);
    // }
    
    
    return new BehaviorSubject<Menu[]>( this.MENUITEMS);
  }
}



