import { Component, EventEmitter, OnInit, Output, Input, ViewChild, OnChanges, SimpleChanges, ChangeDetectorRef, AfterViewChecked } from '@angular/core';
import { ValidateFormsDirective } from 'src/app/directives/validate-forms.directive';
import { ValidatorsService } from 'src/app/shared/services/validators.service';
import { AlertService } from 'src/app/shared/services/alert.service';
import { environment } from 'src/environments/environment';
import { CryptoSharkService } from 'src/app/shared/services/crypto-shark.service';
import { UserService } from 'src/app/shared/services/user.service';
import * as moment from 'moment';
import { WizardComponent } from 'angular-archwizard';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { UrlValidService } from 'src/app/shared/services/url-valid.service';
import { EventService } from 'src/app/shared/services/event.service';
import { EventService as HttpEventService } from 'src/app/shared/services/http/event.service';

@Component({
  selector: 'event-edit',
  templateUrl: './event-edit.component.html',
  styleUrls: ['./event-edit.component.scss']
})
export class EventEditComponent implements OnInit {

  @Output() onClose: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() reset: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() onSave: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() onSubmit: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() modalOptions: any = null;
  @Input() manageEvents: boolean = true;
  @Input() editHost: boolean = false;
  @Input() countActivities: number = 0;
  @ViewChild('wizard') wizard: WizardComponent;
  @ViewChild('inputSearch') inputSearch: any;

  public isLoading: boolean = false;
  public indexList: any[] = [];
  public usersData: any;
  public scheduleList;
  public imagesSelected = [];
  public event: [];
  public eventForm: FormGroup;
  public eventSelected: any;
  public imageEvent = [{ index: 0, id: null, name: null, action: "" }];
  public removedImages: string[] = [];
  public UsersHostList: any[] = [];
  public UsersStaffList: any[] = [];
  public invitationpreview;
  public Invitation;
  public hostId: string;
  public dateRangeFired = false;
  private re = /^[a-zA-Z0-9\._\-]+@[a-z0-9.-]+\.[a-z]{2,3}$/;  // Validar email
  public logo: string = ""
  public isEdit: boolean;
  public isClone: boolean;
  public jsonLanguage: any = {};
  constructor(
    private fb: FormBuilder,
    private toast: AlertService,
    public fieldisValid: ValidateFormsDirective,
    public validatorsService: ValidatorsService,
    private cryptoService: CryptoSharkService,
    private userService: UserService,
    private eventService: EventService,
    private router: Router,
    public urlValidService: UrlValidService,
    private httpEventService: HttpEventService
  ) {
    this.logo = environment.apiUrl + userService?.login?.company?.logo || "../../assets/img/brand/logoFeedbakWhite.svg"
  }

  isInvalidDate(date) { return date.weekday() === 0; }

  async ngOnInit() {
    this.jsonLanguage = this.userService?.user?.jsonLanguage;

    this.isEdit = this.modalOptions.type == 'EDIT'
    this.isClone = this.modalOptions.type == 'CLONE'
    const id = sessionStorage.getItem('eventSelected');
    await this.eventService.setEventSelected(id);
    this.eventForm = this.fb.group({
      name: [null, Validators.compose([Validators.required, Validators.maxLength(250)])],
      description: [null, Validators.compose([Validators.required])],
      date: [null],
      capacity: [null, Validators.compose([Validators.required, Validators.max(10000), Validators.min(0)])],
      duration: [null, Validators.compose([Validators.required, Validators.min(0)])],
      partners: [false],
      extLink: [null],
      maxPartners: [null],
      location: this.fb.group({
        address: [null],
        lat: [null],
        lng: [null],
        references: [null],
        place: [null],
      }),
      invitation: this.fb.group({
        webSite: [null],
        image: [null, Validators.required],
        type: [null],
        colors: this.fb.group({
          primary: ["#000000"],
          secondary: ["#000000"],
          tertiary: ["#000000"],
        }),
        design: [true],
        removedImages: [],
      }),
      host: this.fb.group({
        name: [null, Validators.compose([Validators.required, Validators.maxLength(100)])],
        phone: [null, Validators.compose([Validators.required, Validators.maxLength(10)])],
        email: [null, Validators.compose([Validators.required, Validators.pattern(this.re)])],
        message: [null, Validators.compose([Validators.required, Validators.maxLength(250)])],
        image: [null, Validators.compose([Validators.required])],
        removedImages: []
      }),
      hostId: [null],
      removedImages: [],
      image: [null],
      host_users: [[]],
      staff: [[]]
    });

    if (this.isEdit || this.isClone) {

      await this.detailEvent();

      if (!this.manageEvents) {
        this.eventForm.get('name').disable();
        this.eventForm.get('description').disable();
        this.eventForm.get('date').disable();
        this.eventForm.get('capacity').disable();
        this.eventForm.get('duration').disable();
        this.eventForm.get('partners').disable();
        this.eventForm.get('maxPartners').disable();
        this.eventForm.get('hostId').disable();
        this.eventForm.get('removedImages').disable();
        this.eventForm.get('image').disable();
      }
    }
  }

  public async patchEventForm(event) {

    if (event?.type == 'INFO') {
      
      let urlhaveProtocol = false;
      let validextLink = "";

      //? Validacion para protocolo url //
      urlhaveProtocol = await this.urlValidService.validUrlIsValid(event?.data?.extLink);
      
      if (urlhaveProtocol) validextLink = event?.data?.extLink;
      else event?.data?.extLink ? await this.urlValidService.protocolIsValid(event?.data?.extLink).then(response => validextLink = response.protocol + event?.data?.extLink) : validextLink = event?.data?.extLink;
      //? -------------------------------> //      

      this.eventForm.patchValue({
        name: event?.data?.name,
        description: event?.data?.description,
        date: moment(event?.data?.date?.startDate).format('YYYY-MM-DD HH:mm'),
        capacity: event?.data?.capacity,
        duration: event?.data?.duration,
        maxPartners: event?.data?.maxPartners,
        extLink: validextLink,
        partners: event?.data?.partners,
      });

      this.invitationpreview = this.eventForm.value.name;
    }
    if (event?.type == 'LOCATION') {
      this.eventForm.patchValue({
        location: {
          address: event?.data?.address,
          lat: event?.data?.lat,
          lng: event?.data?.lng,
          references: event?.data?.references,
          place: event.data?.place
        }
      });
    }
    if (event?.type == 'INVIT') {
      
      // let urlhaveProtocol = false;
      // let validextLink = "";

      // //? Validacion para protocolo url //
      // urlhaveProtocol = await this.urlValidService.validUrlIsValid(event?.data?.webSite);

      // if (urlhaveProtocol){
      //   validextLink = event?.data?.webSite
      // }
      // else{
      //   event?.data?.webSite ? await this.urlValidService.protocolIsValid(event?.data?.webSite).then(response => validextLink = response.protocol + event?.data?.webSite) : validextLink = event?.data?.webSite;
      //   console.log("WebSite",event?.data?.webSite);
        
      // }
      const isUrlValid = await this.urlValidService.validUrlIsValid(event?.data?.webSite)
      if (event?.data?.webSite && !isUrlValid) {
            event.data.webSite = `https://${event.data.webSite}`
          }

       //? -------------------------------> //
          
      this.eventForm.patchValue({
        image: event?.data?.image,
        invitation: {
          image: event?.data?.image,
          type: event?.data?.webSite?.trim() ? 'WEB' : 'IMG',
          webSite: event?.data?.webSite,
          colors: {
            primary: event?.data?.colors?.primary,
            secondary: event?.data?.colors?.secondary,
            tertiary: event?.data?.colors?.tertiary,
          },
          design: event?.data?.design,
          removedImages: event?.data?.removedImages,
        }
      });

    }
    if (event?.type == 'HOST') {
      this.eventForm.patchValue({
        host: {
          email: this.cryptoService.encryptAes(event?.data?.email, this.userService.login.company.dk),
          image: event?.data?.image,
          message: this.cryptoService.encryptAes(event?.data?.message, this.userService.login.company.dk),
          name: this.cryptoService.encryptAes(event?.data?.name, this.userService.login.company.dk),
          phone: this.cryptoService.encryptAes(event?.data?.phone, this.userService.login.company.dk),
          removedImages: event?.data?.removedImages,
        }
      });

      this.usersData = event?.usersData;
      this.UsersHostList = event?.UsersHostList;
      this.Submit();
    }
    this.wizard.goToNextStep()
  }

  close() {
    this.eventForm.reset();
    this.onClose.emit();
    this.reset.emit();// Reset idEvent
  }

  public async Submit() {

    try {

      if (this.manageEvents) {

        this.isLoading = true;
        this.indexList = [];

        for (const iterator of this.usersData?.filter(user => { return user.type == "STAFF" })) {
          this.indexList.push(iterator._id);
        }

        let usersId = [];
        this.UsersHostList.forEach(element => { usersId.push(element._id); });

        this.eventForm.value.staff = this.indexList;
        this.eventForm.value.host_users = usersId;
        const formData = new FormData();

        //HOST
        formData.append("host_name", this.eventForm?.value?.host?.name);
        formData.append("host_phone", this.eventForm?.value?.host?.phone);
        formData.append("host_message", this.eventForm?.value?.host?.message);
        formData.append("host_email", this.eventForm?.value?.host?.email);
        formData.append("host_image", this.eventForm?.value.host?.image);
        formData.append("host_img", this.eventForm?.value.host?.image);
        formData.append("host_users", JSON.stringify(this.eventForm.value.host_users));

        if (this.eventForm?.value?.host?.removedImages) {
          formData.append("host_removedImages", this.eventForm?.value?.host?.removedImages);
        }

        //INVITATION
        formData.append("invitation_title", this.eventForm?.value?.invitation?.title);
        formData.append("invitation_image", this.eventForm?.value?.invitation?.image);
        formData.append("invitation_design", this.eventForm?.value?.invitation?.design);
        formData.append("invitation_type", this.eventForm?.value?.invitation?.type);
        formData.append("invitation_colors", JSON.stringify(this.eventForm?.value?.invitation?.colors));
        formData.append("invitation_img", this.eventForm?.value?.invitation?.image);

        if (this.eventForm?.value?.invitation?.webSite) {
          formData.append("invitation_webSite", this.eventForm?.value?.invitation?.webSite);
        }

        if (this.eventForm?.value?.invitation?.removedImages) {
          formData.append("invitation_removedImages", this.eventForm?.value?.invitation?.removedImages);
        }

        //EVENT
        formData.append("event_name", this.eventForm.value.name);
        formData.append("event_type", this.eventForm?.value?.invitation?.type);
        formData.append("event_description", this.eventForm.value.description);
        formData.append("event_image", this.eventForm.value.image);
        formData.append("event_date", this.eventForm.value.date);
        formData.append("event_staff", JSON.stringify(this.eventForm.value.staff));
        formData.append("event_location", JSON.stringify(this.eventForm.value.location));
        formData.append("event_duration", this.eventForm.value.duration);
        formData.append("event_capacity", this.eventForm.value.capacity);
        this.eventForm.value.extLink ? formData.append("event_extLink", this.eventForm.value.extLink) : "";
        formData.append("event_partners", this.eventForm.value.partners);
        this.eventForm.value.maxPartners ? formData.append("event_maxPartners", this.eventForm.value.maxPartners) : "";

        if (this.modalOptions.type == 'ADD') {
          // create event
          await this.httpEventService.addEvent(formData)
            .then(async () => { this.toast.successAlert(this.jsonLanguage?.web?.default?.components?.events?.new_event_modal?.event_created_message  || "Evento creado exitosamente!", '', this.jsonLanguage?.web?.default?.components?.events?.new_event_modal?.event_created_btn_label  ||'Aceptar'); })
          .catch((e) => {
            console.log(e);
          })
        }

        else if (this.isEdit) {

          //EVENT
          formData.append("event_id", this.eventSelected._id);
          formData.append("host_id", this.eventSelected.hostId);
          formData.append("invitation_id", this.eventSelected.invitationId);


          // edit event
          await this.httpEventService.editEvent(formData)
            .then(async () => { this.toast.successAlert(this.jsonLanguage?.web?.default?.components?.events?.new_event_modal?.event_updated_message  || "Actualizado exitosamente!", '',this.jsonLanguage?.web?.default?.components?.events?.new_event_modal?.event_created_btn_label   || 'Aceptar') })
          .catch((e) => {
            console.log(e);
          })

        } else if (this.isClone) {

          // clone event
          await this.httpEventService.addEvent(formData)
            .then(async () => {
            this.toast.successAlert(this.jsonLanguage?.web?.default?.components?.events?.new_event_modal?.event_cloned_message  || "Evento clonado exitosamente!", '', this.jsonLanguage?.web?.default?.components?.events?.new_event_modal?.event_created_btn_label || 'Aceptar');
            await this.eventService.clearEventSelected();
            this.router.navigate(['/events']);
          })
          .catch((e) => {
            console.log(e);
          })
        }
      }
      this.onSave.emit();

    } 
    catch (error) { console.log(error) }
    finally { this.isLoading = false; }
  }


  get eventFormControl() { return this.eventForm.controls; }

  public async detailEvent() {

    const _id = this.modalOptions._id;
    
    try {
      this.isLoading = true;
      await this.httpEventService.getEvent(_id).then((response) => {
        this.eventSelected = response.data
        this.isClone ? this.eventSelected.name = `${this.eventSelected.name}  ${this.jsonLanguage?.web?.default?.components?.events?.new_event_modal?.copy_concat_text || "(Copia)" }` : "";
      })


      const invitation = await this.httpEventService.getInvitation(this.eventSelected.invitationId);
      
      this.imageEvent[0].name = environment.apiUrl + this.eventSelected.image;
      this.eventForm.patchValue({
        name: this.eventSelected?.name,
        date: moment(this.eventSelected?.date).format("YYYY-MM-DD HH:mm"),
        duration: this.eventSelected?.duration,
        capacity: this.eventSelected?.capacity,
        description: this.eventSelected?.description,
        partners: this.eventSelected?.partners,
        maxPartners: this.eventSelected?.maxPartners,
        location: this.eventSelected?.location,
        invitation: {
          title: invitation?.title,
          webSite: invitation?.webSite,
          colors: {
            primary: invitation.colors?.primary,
            secondary: invitation.colors?.secondary,
            tertiary: invitation.colors?.tertiary
          },
          design: invitation.design,
          event: invitation?.event,
          type: invitation?.type,
          image: this.eventSelected?.image
        },
        hostId: this.eventSelected?.invitationId,
        image: this.eventSelected?.image,
        host_users: this.eventSelected?.hostUsers,
        staff: this.eventSelected?.staff
      });
      

      if (this.eventSelected.scheduleId != undefined) {
        this.scheduleList = await this.httpEventService.getSchedule(this.eventSelected.scheduleId)
      }      

    } 
    catch (error) { console.log(error) }
    finally { this.isLoading = false }
  }

  public assignSchedule(scheduleList) {
    this.scheduleList.activities = scheduleList;
  }

  public getUserName(idUser: string): string {
    return this.usersData.find(user => { return user._id == idUser })?.name || "";
  }

  public removeIndex(index: number): void {
    this.indexList.splice(index, 1);
  }

  public onSaveEmit() {
    this.onSave.emit();
  }

  public returnModalName(): any {
    let value: string; 
    this.modalOptions.type == 'ADD' ? value = this.jsonLanguage?.web?.default?.components?.events?.new_event_modal?.title_new || "Nuevo Evento" : this.isClone ? value = this.jsonLanguage?.web?.default?.components?.events?.new_event_modal?.title_clone  || "Clonar Evento" :
     (this.manageEvents) && this.isEdit ? value = this.jsonLanguage?.web?.default?.components?.events?.new_event_modal?.title_edit  || "Editar Evento" : value = this.jsonLanguage?.web?.default?.components?.events?.new_event_modal?.title_view || "Detalle del Evento";

    return value;
  }

}