import { DomSanitizerPipe } from './dom-sanitizer.pipe';
import { NgModule } from '@angular/core';
import { momentPipe } from './moment.pipe';
import { DecryptPipe } from './decrypt.pipe';

import { DecryptChatPipe } from './decrypt-chat.pipe';
import { DecryptGuestInfoPipe } from './decrypt-guest-info.pipe';
import { PhotoGuestPipe } from './photo-guest.pipe';
import { StatusPipe } from './status.pipe';
import { ImagesLoadPipe } from './images-load.pipe';



@NgModule({
	declarations: [ DomSanitizerPipe,momentPipe,DecryptPipe, DecryptChatPipe, DecryptGuestInfoPipe, PhotoGuestPipe, StatusPipe, ImagesLoadPipe ],
	imports: [],
	exports: [ DomSanitizerPipe, momentPipe, DecryptPipe, DecryptChatPipe, DecryptGuestInfoPipe, PhotoGuestPipe, StatusPipe, ImagesLoadPipe ]
})
export class PipesModule {}
