import { Component, OnInit, OnChanges } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { EventService } from 'src/app/shared/services/event.service';
import { UserService } from 'src/app/shared/services/user.service';
import { ModalEventSelectComponent } from '../modal-event-select/modal-event-select.component';

@Component({
  selector: 'app-event-select',
  templateUrl: './event-select.component.html',
  styleUrls: ['./event-select.component.scss'],
  host: {
    "class": "card border-0",
    "(click)": "selectEvent()"
  }
  
})


export class EventSelectComponent implements OnInit {

  public jsonLanguage:any = {};

  constructor(
    public eventService: EventService,
    private modalService: NgbModal,
    public userService: UserService
  ) { }

  async ngOnInit() {
    const id = sessionStorage.getItem('eventSelected');
    this.jsonLanguage = this.userService?.user?.jsonLanguage;
      
  }


  ngOnChanges() {
    
  }

  selectEvent(template: any, size = 'xl') {
    // this.modalService.open(ModalEventSelectComponent, { size: size})
  }
}
