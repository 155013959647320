import { HttpClient, HttpHeaders, HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HttpService {

  constructor(private http: HttpClient) { }
  
  public postN = (url: string, body: any, contentType?: string): Observable<any> => this.http.post(`${environment.apiUrl}${url}`, body);
  public post = (url: string, body: any, contentType?: string): Observable<any> => this.http.post(`${environment.apiUrl}${url}`, body, contentType ? { headers: { "Content-Type": contentType } } : {});
  public put = (url: string, body: any, contentType?: string): Observable<any> => this.http.put(`${environment.apiUrl}${url}`, body, contentType ? { headers: { "Content-Type": contentType } } : {});
  public patch = (url: string, body: any, contentType?: string): Observable<any> => this.http.patch(`${environment.apiUrl}${url}`, body, contentType ? { headers: { "Content-Type": contentType } } : {});
  public postBlob = (url: string, body: any, contentType?: string): Observable<any> => this.http.post(`${environment.apiUrl}${url}`, body, { responseType: 'blob', headers: { "Content-Type": contentType } });
  public get = (url: string, contentType?: string): Observable<any> => this.http.get(`${environment.apiUrl}${url}`, contentType ? { headers: { "Content-Type": contentType } } : {});
  public getBlob = (url: string, contentType?: string): Observable<any> => this.http.get(`${environment.apiUrl}${url}`, { responseType: 'blob', headers: { "Content-Type": contentType } });
  public getText = (url: string, contentType?: string): Observable<any> => this.http.get(`${environment.apiUrl}${url}`, { responseType: 'text', headers: { "Content-Type": contentType } });
  public delete = (url: string, contentType?: string): Observable<any> => this.http.delete(`${environment.apiUrl}${url}`, contentType ? { headers: { "Content-Type": contentType } } : {});
  public getFilter = (url: string, params: any, contentType?: string): Observable<any> => this.http.get(`${environment.apiUrl}${url}`, { params, headers: { "Content-Type": contentType } });

  public getEva = (url: string, contentType?: string): Observable<any> => this.http.get(`${environment.urlAdmin}${url}`, contentType ? { headers: { "Content-Type": contentType } } : {});
  //public postMonday = (url: string, body: any, contentType?: any): Observable<any> => this.http.post(`${environment.url_monday}${url}`, body, contentType ? { headers: { "Content-Type": contentType } } : {}); 
  //public postMonday = (url: string, body: any, contentType?: any): Observable<any> => this.http.post(`${environment.monday}${url}`, body, { responseType: 'blob', headers: { "Content-Type": contentType } });
  public deleteBody = (url: string, body: any): Observable<any> => {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: body
    };
    return this.http.delete(`${environment.apiUrl}${url}`, options);
  };

  public bodyWithToken = (url: string, body: any, token: string): Observable<any> => {
    const options = {
      headers: new HttpHeaders({
        'token': token,
      })
    };
    return this.http.post(`${environment.apiUrl}${url}`, body, options);
  };

  
}

