import { Injectable } from '@angular/core';
import { HttpService } from './http.service';

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {

  public _Notifications: any = [];
  public unread: number = 0;


  constructor(
    public httpService: HttpService
  ) { 
  }

  update(notification: any){
    this._Notifications.unshift(notification);
  }

  updateNotifications(){
    this.httpService.get(`api/notifications/view/all`).toPromise();
    for (const notification of this._Notifications) {
      notification.view = true;
    }
    this.unread = 0
  }

  async listNotifications(){
    this._Notifications = await this.httpService.get(`api/notifications/list`).toPromise();
    this.unread = this._Notifications.filter(item => {return !item?.view})?.length;
  }

}
