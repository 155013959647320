import { Component, Input, OnInit } from '@angular/core';
import * as moment from 'moment';

@Component({
  selector: 'portal-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {

  @Input() jsonLanguage;
  public year: string;

  //public languages = [{code: 'mx', name: 'Español'},{code: 'us', name: 'English'},{code: 'ht', name: 'kreyòl'}];
  //public selectedLanguage = this.languages[0].code.valueOf();

  constructor() { }

  ngOnInit(): void {
    this.year = moment().format("YYYY");
  }

}
