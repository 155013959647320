import { Component, EventEmitter, OnInit, Output, Input } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CryptoSharkService } from '../../../shared/services/crypto-shark.service';
import { HttpService } from 'src/app/shared/services/http.service';
import { AlertService } from 'src/app/shared/services/alert.service';
import { UserService } from 'src/app/shared/services/user.service';

@Component({
  selector: 'modal-credentials',
  templateUrl: './modal-credentials.component.html',
  styleUrls: ['./modal-credentials.component.scss']
})
export class ModalCredentialsComponent implements OnInit {

  //Variables
  public credentialsForm: FormGroup;
  public error: string = null;
  public user: any = null;
  public errorConfirmPassword: boolean = false;
  public errorConfirmNip: boolean = false;
  public passTypeText: boolean = false;
  public nipTypeText: boolean = false;
  public validForm: boolean = false;
  public jsonLanguage:any = {};
  @Output() onSave: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() onClose: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() reset: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Input() idUser;
  @Input() userToken;

  constructor(
    private fb: FormBuilder,
    private httpService: HttpService,
    private cryptoService: CryptoSharkService,
    public toast: AlertService,
    private userService: UserService
  ) { }

  ngOnInit(): void {
    this.credentialsForm = this.fb.group({
      new_password: ['', Validators.required],
      confirm_password: ['', Validators.required],
      new_nip: ['', Validators.required],
      confirm_nip: ['', Validators.required]
    });
    this.jsonLanguage = this.userService?.user?.jsonLanguage;

  }

  get formFields() {
    return this.credentialsForm.controls;
  }

  async submit() {
    this.saveChanges();
  }

  async saveChanges() {
    
    try {

      if (this.userToken !== undefined) {

        let pass = this.credentialsForm.controls['new_password'].value.toString();
        let nip = this.credentialsForm.controls['new_nip'].value.toString();

        await this.httpService.bodyWithToken(`api/login/credentials`, 
          {
            newPass: this.cryptoService.encryptSha(pass),
            newNip: this.cryptoService.encryptSha(nip),
          }, 
          this.userToken
        )
        .toPromise()
        .then(async (response: any) => {
          this.onSave.emit();
          this.close();
          this.toast.successAlert(this.jsonLanguage?.web?.default?.components?.change_credentials?.credentials_created_successful || "¡Credenciales generadas correctamente!", '',this.jsonLanguage?.web?.default?.components?.change_credentials?.accept_btn_label  || 'Aceptar');
        })
        .catch((e) => { console.log(e.error.message) });
      }

    } 
    catch (error) { console.log(error)}

  }

  async close() {
    this.credentialsForm.reset();
    this.userToken = null;
    this.reset.emit();
    this.onClose.emit();
  }

  async onPasswordChange() {

    if (this.confirm_password.value == this.new_password.value) {
      this.errorConfirmPassword = false;
      this.confirm_password.setErrors(null);
    } else {
      this.errorConfirmPassword = true;
      this.confirm_password.setErrors({ mismatch: true });
    }

  }

  async onNipChange() {

    let errores = false;
    let nipVal = this.new_nip.value.toString();

    if (nipVal.length !== 4) {
      errores = true;
      this.new_nip.setErrors({ size: true });
    }
    else  this.new_nip.setErrors({ size: false });

    if (this.confirm_nip.value == this.new_nip.value) {
      this.confirm_nip.setErrors({ mismatch: false });
      this.errorConfirmNip = false;
    }
    else {
      errores = true;
      this.confirm_nip.setErrors({ mismatch: true });
      this.errorConfirmNip = true;
    }

    if (!errores) {
      this.new_nip.setErrors(null);
      this.confirm_nip.setErrors(null);
    }

  }

  // getting the form control elements
  get new_password(): AbstractControl {
    return this.credentialsForm.controls['new_password'];
  }

  get confirm_password(): AbstractControl {
    return this.credentialsForm.controls['confirm_password'];
  }

  get new_nip(): AbstractControl {
    return this.credentialsForm.controls['new_nip'];
  }

  get confirm_nip(): AbstractControl {
    return this.credentialsForm.controls['confirm_nip'];
  }

  async toggleInputType(input: string) {
    if (input === "password") {
      this.passTypeText = !this.passTypeText;
    }

    if (input === "nip") {
      this.nipTypeText = !this.nipTypeText;
    }
  }

}