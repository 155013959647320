import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'schedule-item',
  templateUrl: './schedule-item.component.html',
  styleUrls: ['./schedule-item.component.scss']
})
export class ScheduleItemComponent implements OnInit {

  @Input() detail: boolean = true;
  @Input() manageEvents: boolean = false;
  @Output() onEdit: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() onDelete: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Input() activitie: any;
  @Input() rawImages: any = [];
  @Input() index = 0;

  statusColor = {
    created: "info",
    active: "danger",
    finished: "dark",
    postponed: "warning",
    canceled: "danger",
    deleted: "dark",
  }
  public apiUrl = environment.apiUrl;
  constructor() { }

  ngOnInit(): void {
    console.log();
    
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!changes.firstChange) {
      // console.log(changes);
      this.rawImages = changes.rawImages.currentValue;
      this.index = changes.index.currentValue;
      // console.log("changes.rawImages.currentValue",changes.rawImages.currentValue);
      // console.log("this.rawImages",this.rawImages);
      
    }
  }

  public editActivitie(event:any){
    this.onEdit.emit(event);    
  }
  public deleteActivitie(event:any){    
    this.onDelete.emit(event);    
  }



}
